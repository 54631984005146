import { DefaultPaymentNotificationHandler } from 'features/payments/modals/handlers/defaultPaymentNotificationHandler';
import { RouteLayout } from 'features/ui/layout/routeLayout';
import { Typography } from 'features/ui/typography/typography';
import _ from 'lodash';
import { useCallback, useEffect, useState } from 'react';
import paths from 'routing/utils';
import { InfiniteScroll } from 'shared/hooks/useInfiniteScroll';
import { TokenTrack } from 'shared/types/tokenTrack';
import { useGetTokenTracksMutation } from 'store/api/endpoints/tokenTrackEndpoints';
import { CurrentBalanceCard } from '../../ui/payments/currentBalanceCard';
import { PaymentHistoryItem } from './paymentHistoryItem';

export const PaymentHistory = () => {
  const [allTokenTracks, setAllTokenTracks] = useState<TokenTrack[]>([]);
  const [totalPages, setTotalPages] = useState<number>(0);
  const [currentPage, setCurrentPage] = useState<number>(0);
  // rtk
  const [getTokenTracks, { isLoading }] = useGetTokenTracksMutation();

  const requestTokenTracks = useCallback(
    (page: number) => {
      getTokenTracks({
        page: page,
        size: 10
      })
        .unwrap()
        .then(result => {
          setAllTokenTracks(prev => [...prev, ...result.content]);
          setTotalPages(result.totalPages);
        })
        .catch(() => {
          setAllTokenTracks([]);
          setTotalPages(0);
        });
    },
    [getTokenTracks]
  );

  useEffect(() => {
    requestTokenTracks(0);
  }, [requestTokenTracks]);

  const loadMore = () => {
    const nextPage = currentPage + 1;
    setCurrentPage(nextPage);
    requestTokenTracks(nextPage);
  };

  const isNoResult = !isLoading && _.isEmpty(allTokenTracks);

  const infiniteScrollStories = allTokenTracks.map(tt => ({ ...tt, label: tt.description }));

  return (
    <RouteLayout backRoute={paths.profile.base} backRouteText="Historia zakupów">
      <DefaultPaymentNotificationHandler />
      <div className="my-5 mx-3" style={{ position: 'relative' }}>
        <CurrentBalanceCard source={paths.payments.history} />
        <div className="my-4">
          <InfiniteScroll
            divider
            list={infiniteScrollStories}
            isLoading={isLoading}
            hasMore={currentPage !== totalPages - 1}
            loadMore={loadMore}
            renderListItem={element => <PaymentHistoryItem tokenTrack={element} />}
            renderEmptyListMessage={() =>
              isNoResult ? (
                <Typography variant="description" classNames="pb-5">
                  Brak historii zakupów...
                </Typography>
              ) : (
                <></>
              )
            }
            renderLoadingRowItem={() => (
              <div className="d-flex justify-content-center p-4">
                <Typography variant="h4">Ładowanie...</Typography>
              </div>
            )}
          />
        </div>
      </div>
    </RouteLayout>
  );
};
