import DOMPurify from 'dompurify';
import { router } from 'routing/routes';
import paths from 'routing/utils';
import { useHasSufficientSub } from 'shared/hooks/useHasSufficientSub';
import { Creator, StoryPreview } from 'shared/types/story';
import { rgba } from 'shared/utils/commonUtils';
import { cSecondary } from 'shared/utils/styleCommon';
import { CustomImage, ThumbnailSize } from '../image/customImage';
import { Typography } from '../typography/typography';

type Props = {
  story: StoryPreview;
  searchText?: string;
};

export const HorizontalStoryPreview = (props: Props) => {
  const requiredSubscription = props.story.readAccessLevel;
  const hasSufficientSub = useHasSufficientSub(requiredSubscription);

  function highlightText() {
    const title = props.story.title;
    if (!props.searchText) {
      return <Typography variant="h5">{title}</Typography>;
    }
    const searchText = props.searchText;
    const startIndex = title.toLowerCase().indexOf(searchText.toLowerCase());
    if (startIndex === -1) {
      return (
        <Typography variant="h5" styles={{ color: rgba(cSecondary, 0.5) }}>
          {title}
        </Typography>
      );
    }

    const endIndex = startIndex + searchText.length;
    return (
      <Typography variant="h5" inline styles={{ color: rgba(cSecondary, 0.5), whiteSpace: 'break-spaces' }}>
        {title.substring(0, startIndex)}
        <Typography variant="h5" inline styles={{ color: cSecondary }}>
          {title.substring(startIndex, endIndex)}
        </Typography>
        {title.substring(endIndex)}
      </Typography>
    );
  }

  const dirtyHTML = props.story.description ? props.story.description : `Treść: ${props.story.limitedContent}`;

  const sanitizedHTML = DOMPurify.sanitize(dirtyHTML, {
    RETURN_TRUSTED_TYPE: true,
    FORCE_BODY: true,
    ALLOWED_TAGS: [],
    ADD_ATTR: ['class', 'id']
  });

  return (
    <div
      key={props.story.storyId}
      style={{ opacity: hasSufficientSub ? 1 : 0.3 }}
      role="button"
      onClick={() => {
        hasSufficientSub
          ? router.navigate(paths.story + `/${props.story.storyId}`)
          : router.navigate(paths.subscription.base + `/${requiredSubscription}`);
      }}
    >
      <div className="d-flex align-items-center gap-2">
        <CustomImage
          directory={props.story.creator === Creator.SYSTEM || props.story.templateId ? 'common' : props.story.ownerId}
          filename={props.story.image}
          styles={{ borderRadius: '10px', flex: 'none' }}
          width={'100px'}
          height={'100px'}
          thumbnail={ThumbnailSize.THUMBNAIL_200X200}
          skeleton
          noImagePlaceholder
        />

        <div className="d-flex flex-column gap-2">
          {highlightText()}
          <div dangerouslySetInnerHTML={{ __html: sanitizedHTML }}></div>
        </div>
      </div>
    </div>
  );
};
