import App from 'App';
import 'bootstrap-icons/font/bootstrap-icons.css';
import { Hotjar } from 'hotJar';
import ThemeProvider from 'react-bootstrap/esm/ThemeProvider';
import ReactDOM from 'react-dom';
import { Provider } from 'react-redux';
import { GeneratorTimerProvider } from 'shared/context/generatorTimerContext';
import { store } from 'store/store';
import './App.scss';
import * as serviceWorkerRegistration from './serviceWorkerRegistration';

export const PRODUCTION_ENV = 'production';
export const DEVELOPMENT_ENV = 'development';

ReactDOM.render(
  <Hotjar>
    <Provider store={store}>
      <ThemeProvider breakpoints={['xxxl', 'xxl', 'xl', 'lg', 'md', 'sm', 'xs', 'xxs']} minBreakpoint="xxs">
        <GeneratorTimerProvider>
          <App />
        </GeneratorTimerProvider>
      </ThemeProvider>
    </Provider>
  </Hotjar>,
  document.getElementById('root')
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://cra.link/PWA
serviceWorkerRegistration.register();
