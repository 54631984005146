import { RouteLayout } from 'features/ui/layout/routeLayout';
import { useCallback, useEffect } from 'react';
import { useNavigate, useSearchParams } from 'react-router-dom';
import paths from 'routing/utils';
import { useInterval } from 'shared/hooks/useInterval';
import { TransactionStatus } from 'shared/types/transaction';
import { useGetLoggedUserQuery } from 'store/api/endpoints/accountEndpoints';
import { extendedApi as productApi } from 'store/api/endpoints/productEndpoints';
import { extendedApi as transactionsApi } from 'store/api/endpoints/transactionEndpoint';
import { useAppDispatch, useAppSelector } from 'store/hooks';
import { addAlert } from 'store/slices/alertsSlice';
import { resetPaymentState, setPaymentInfo } from 'store/slices/paymentSlice';
import { PaymentLoader } from './token-packages/paymentLoader';

export const PaymentRedirect = () => {
  // redux
  const source = useAppSelector(state => state.paymentSlice.source);
  const dispatch = useAppDispatch();
  // rtk
  const { refetch: refetchUser } = useGetLoggedUserQuery();
  // other
  const navigate = useNavigate();
  const [searchParams] = useSearchParams();
  const transactionId = searchParams.get('transactionId');
  const status = searchParams.get('status');

  useEffect(() => {
    if (status === 'ERROR') {
      dispatch(setPaymentInfo({ status: 'ERROR' }));
    }
  }, [status, dispatch]);

  const updatePaymentStatus = useCallback(() => {
    if (transactionId) {
      dispatch(transactionsApi.endpoints.getTransactionById.initiate({ transactionId }))
        .unwrap()
        .then(transaction => {
          if (
            transaction.status === TransactionStatus.FULFILLED ||
            transaction.status === TransactionStatus.FULFILLED_OVERPAY ||
            transaction.status === TransactionStatus.FULFILLED_UNDERPAY
          ) {
            dispatch(productApi.endpoints.getProductById.initiate(transaction.productId))
              .unwrap()
              .then(product => {
                dispatch(setPaymentInfo({ status: 'SUCCESS', tokens: product.properties.tokens }));
                refetchUser();
                navigate(source ?? paths.root);
              })
              .catch(() => dispatch(addAlert({ color: 'danger', text: 'Coś poszło nie tak, spróbuj odświeżyć stronę.' })));
          } else {
            dispatch(setPaymentInfo({ status: 'ERROR' }));
          }
        })
        .catch(() => dispatch(addAlert({ color: 'danger', text: 'Coś poszło nie tak, spróbuj odświeżyć stronę.' })));
    }
  }, [dispatch, navigate, refetchUser, source, transactionId]);

  useEffect(() => {
    updatePaymentStatus();
  }, [updatePaymentStatus]);

  useInterval(() => {
    updatePaymentStatus();
  }, 3000);

  return (
    <RouteLayout
      hideBottomNavigation
      actions={[
        {
          label: 'Anuluj',
          action: () => {
            dispatch(resetPaymentState());
            navigate(source ?? paths.root);
          }
        }
      ]}
    >
      <PaymentLoader />
    </RouteLayout>
  );
};
