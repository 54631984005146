import { PredefinedSoundtrack } from 'shared/types/soundtrack';
import { HTTP_METHODS, apiSlice } from '../api';

const extendedApi = apiSlice.injectEndpoints({
  endpoints: builder => ({
    getPredefinedSoundtracks: builder.query<PredefinedSoundtrack[], void>({
      query: () => ({
        url: `/public/predefined-soundtracks`,
        method: HTTP_METHODS.GET
      })
    })
  }),
  overrideExisting: false
});

export const { useGetPredefinedSoundtracksQuery } = extendedApi;
