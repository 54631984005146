import { TemplateParam } from 'shared/types/template';

export const getMainTemplateParams = (charactersNumber: string): TemplateParam[] => {
  switch (charactersNumber) {
    case '1':
      return [
        {
          key: 'MAIN_HERO1',
          label: 'Główny bohater'
        }
      ];
    case '2':
      return [
        {
          key: 'MAIN_HERO1',
          label: 'Główny bohater'
        },
        {
          key: 'MAIN_HERO2',
          label: 'Poboczny bohater'
        }
      ];
    case '3':
      return [
        {
          key: 'MAIN_HERO1',
          label: 'Główny bohater'
        },
        {
          key: 'MAIN_HERO2',
          label: 'Poboczny bohater'
        },
        {
          key: 'MAIN_HERO3',
          label: 'Poboczny bohater'
        }
      ];
    default:
      return [];
  }
};
