import { Story, StoryPreview } from 'shared/types/story';
import { HTTP_METHODS, apiSlice } from '../api';

export interface SearchRequest {
  operator: Operator;
  searchCriteriaGroups: SearchCriteriaGroup[];
}

export interface SearchCriteriaGroup {
  groupOperator: Operator;
  searchCriteria: SearchCriteria[];
}
export interface SearchCriteria {
  filterKey: string;
  value?: string;
  values?: string[];
  operation: SearchOperation;
}

export enum Operator {
  AND = 'AND',
  OR = 'OR'
}

export enum SearchOperation {
  CONTAINS = 'CONTAINS',
  DOES_NOT_CONTAIN = 'DOES_NOT_CONTAIN',
  EQUAL = 'EQUAL',
  NOT_EQUAL = 'NOT_EQUAL',
  BEGINS_WITH = 'BEGINS_WITH',
  DOES_NOT_BEGIN_WITH = 'DOES_NOT_BEGIN_WITH',
  ENDS_WITH = 'ENDS_WITH',
  DOES_NOT_END_WITH = 'DOES_NOT_END_WITH',
  NULL = 'NULL',
  NOT_NULL = 'NOT_NULL',
  GREATER_THAN = 'GREATER_THAN',
  GREATER_THAN_EQUAL = 'GREATER_THAN_EQUAL',
  LESS_THAN = 'LESS_THAN',
  LESS_THAN_EQUAL = 'LESS_THAN_EQUAL',
  NOT_INCLUDES = 'NOT_INCLUDES',
  INCLUDES = 'INCLUDES'
}

export interface Page<T> {
  content: T[];
  size: number;
  totalElements: number;
  totalPages: number;
}

const extendedApi = apiSlice.injectEndpoints({
  endpoints: builder => ({
    searchStories: builder.mutation<Page<StoryPreview>, { page: number; size: number; searchRequest: SearchRequest }>({
      query: data => ({
        url: `/public/stories/search?page=${data.page}&size=${data.size}&sortField=createdDate`,
        method: HTTP_METHODS.POST,
        body: data.searchRequest
      })
    }),
    getStoriesByIds: builder.query<Story[], { userId: string; ids: string[] }>({
      query: data => ({
        url: `/users/${data.userId}/stories?ids=${data.ids}`,
        method: HTTP_METHODS.GET
      })
    }),
    getStoryById: builder.query<Story, string>({
      query: id => ({
        url: `/public/stories/${id}`,
        method: HTTP_METHODS.GET
      })
    }),
    deleteStories: builder.mutation<void, string>({
      query: storiesId => ({
        url: `/stories/${storiesId}`,
        method: HTTP_METHODS.DELETE
      })
    })
  }),
  overrideExisting: false
});

export const { useSearchStoriesMutation, useGetStoriesByIdsQuery, useGetStoryByIdQuery, useDeleteStoriesMutation } = extendedApi;
