import { Typography } from 'features/ui/typography/typography';
import { Col, Container, Modal, Row } from 'react-bootstrap';
import activitiesSpiralImage from '../../shared/images/activities_spiral.png';

type Props = {
  show: boolean;
  title: string;
  content: TrustedHTML;
  onHide: () => void;
};

export const ActivitiesContentModal = (props: Props) => (
  <Modal fullscreen centered show={props.show} onHide={props.onHide}>
    <Modal.Header closeButton style={{ position: 'relative' }}>
      <img src={activitiesSpiralImage} alt="" style={{ position: 'absolute', left: 0, right: 0, margin: '0 auto', top: 0 }} />
    </Modal.Header>
    <Modal.Body>
      <Container className="h-100" style={{ overflowY: 'auto', maxWidth: 'none' }}>
        <Row className="justify-content-sm-center h-100">
          <Col xs={12} sm={8} md={6} lg={6} xl={6} xxl={6} style={{ padding: 0 }}>
            <Typography variant="h1" styles={{ marginBottom: 8 }}>
              {props.title}
            </Typography>
            <div className="lexicon-html" dangerouslySetInnerHTML={{ __html: props.content }}></div>
          </Col>
        </Row>
      </Container>
    </Modal.Body>
  </Modal>
);
