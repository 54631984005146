export interface PredefinedSoundtrack {
  id?: string;
  value: string;
  image?: string;
  cost: number;
  type: SoundtrackType;
  order?: number;
}

export enum SoundtrackType {
  WRONA = 'WRONA'
}
