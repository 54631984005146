import { Alerts } from 'features/alerts/alerts';
import { useEffect, useState } from 'react';
import { RouterProvider } from 'react-router-dom';
import { router } from 'routing/routes';
import { APP_VERSION } from 'serviceWorkerRegistration';
import { extendedApi, useUpdateAccountMutation } from 'store/api/endpoints/accountEndpoints';
import { useAppDispatch, useAppSelector } from 'store/hooks';
import { SurveyModal } from 'surveyModal';

const App = () => {
  const [isSurveyModalOpen, setIsSurveyModalOpen] = useState<boolean>(false);
  // redux
  const loggedUser = useAppSelector(state => state.authSlice.loggedUser);
  const dispatch = useAppDispatch();
  // rtk
  const [update] = useUpdateAccountMutation();

  useEffect(() => {
    console.info(`App prod version: ${APP_VERSION}`);
  }, []);

  useEffect(() => {
    if (loggedUser && !loggedUser.surveyShown) {
      setIsSurveyModalOpen(true);
    }
  }, [loggedUser]);

  const onCloseSurveyModal = () => {
    if (loggedUser) {
      update({
        id: loggedUser.id,
        firstName: loggedUser.firstName,
        email: loggedUser.email,
        playbackRate: loggedUser.playbackRate,
        surveyShown: true
      })
        .unwrap()
        .then(() => {
          dispatch(extendedApi.endpoints.getLoggedUser.initiate(undefined, { forceRefetch: true }));
        })
        .catch(e => console.error(e));
    }
    setIsSurveyModalOpen(false);
  };

  return (
    <>
      <Alerts />
      <RouterProvider router={router} />
      <SurveyModal open={isSurveyModalOpen} onClose={onCloseSurveyModal} />
    </>
  );
};

export default App;
