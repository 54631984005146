import { ProgressLoader } from 'features/story-creator/loader/progressLoader';
import { RouteLayout } from 'features/ui/layout/routeLayout';
import { Typography } from 'features/ui/typography/typography';
import { useState } from 'react';
import { useInterval } from 'shared/hooks/useInterval';

export const PaymentLoader = () => {
  const [count, setCount] = useState(0);

  useInterval(() => {
    setCount(prev => prev + 1);
  }, 1000);

  return (
    <RouteLayout>
      <div className="d-flex flex-column justify-content-center align-items-center gap-3 mt-5 mx-3 text-center">
        <ProgressLoader count={count} />
        <Typography variant="description" classNames="mt-4">
          Oczekiwanie na finalizację płatności
        </Typography>
      </div>
    </RouteLayout>
  );
};
