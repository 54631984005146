import _ from 'lodash';
import { useEffect } from 'react';
import { useLocation } from 'react-router-dom';
import { router } from 'routing/routes';
import { AuthorityType } from 'shared/types/account';
import { SubscriptionType } from 'shared/types/users';
import { useAppDispatch, useAppSelector } from 'store/hooks';
import paths from '../utils';

interface SecuredGuardProps {
  children: React.ReactNode;
  authority: AuthorityType;
  requiredSubscription?: SubscriptionType;
}

export const SecuredGuard = ({ children, authority, requiredSubscription }: SecuredGuardProps): JSX.Element => {
  const dispatch = useAppDispatch();
  const loggedUser = useAppSelector(state => state.authSlice.loggedUser);
  const location = useLocation();
  const isAuthorized = _.includes(loggedUser?.authorities, authority);

  useEffect(() => {
    if (!isAuthorized) {
      router.navigate(paths.subscription.base + `/${requiredSubscription ?? SubscriptionType.FREE}`, {
        state: { prevRoute: location.pathname }
      });
    }
  }, [isAuthorized, dispatch, requiredSubscription, location]);

  if (isAuthorized) {
    return <>{children}</>;
  }
  return <></>;
};
