import { CustomImage } from 'features/ui/image/customImage';
import { RouteLayout } from 'features/ui/layout/routeLayout';
import _ from 'lodash';
import { useCallback, useEffect, useState } from 'react';
import { router } from 'routing/routes';
import paths from 'routing/utils';
import { InfiniteScroll } from 'shared/hooks/useInfiniteScroll';
import { StorySeries } from 'shared/types/series';
import { pxToRem } from 'shared/utils/commonUtils';
import { useSearchSeriesMutation } from 'store/api/endpoints/seriesEndpoints';
import { Operator, SearchOperation } from 'store/api/endpoints/storyEndpoints';

import { Typography } from '../ui/typography/typography';
import { AdminSeriesPreview } from './adminSeriesPreview';
import { AdminSeriesSearch } from './adminSeriesSearch';
import { Creator } from 'shared/types/story';

export const AdminSeriesList = () => {
  const [searchText, setSearchText] = useState('');
  const [isSearchFocused, setIsSearchFocused] = useState(false);
  const [allSeries, setAllSeries] = useState<StorySeries[]>([]);
  const [totalPages, setTotalPages] = useState<number>(0);
  const [currentPage, setCurrentPage] = useState<number>(0);
  // rtk
  const [searchSeries, { isLoading }] = useSearchSeriesMutation();

  const requestStories = useCallback(
    (page: number) => {
      searchSeries({
        page: page,
        size: 10,
        searchRequest: {
          operator: Operator.AND,
          searchCriteriaGroups: [
            {
              groupOperator: Operator.AND,
              searchCriteria: [{ filterKey: 'creator', value: Creator.SYSTEM, operation: SearchOperation.EQUAL }]
            }
          ]
        }
      })
        .unwrap()
        .then(result => {
          setAllSeries(prev => (page > 0 ? [...prev, ...result.content] : result.content));
          setTotalPages(result.totalPages);
        })
        .catch(() => {
          setAllSeries([]);
          setTotalPages(0);
        });
    },
    [searchSeries]
  );

  useEffect(() => {
    if (_.isEmpty(searchText)) {
      requestStories(0);
    }
  }, [requestStories, searchText]);

  const loadMore = () => {
    const nextPage = currentPage + 1;
    setCurrentPage(nextPage);
    requestStories(nextPage);
  };

  const infiniteScrollStories = allSeries?.map(s => ({ ...s, id: s.id, label: s.title }));

  return (
    <RouteLayout backRoute={paths.admin.series.base}>
      <Typography variant="h1" classNames="pb-3">
        Wyszukaj serię
      </Typography>

      <AdminSeriesSearch
        onSearchFocusChange={setIsSearchFocused}
        onSearchTextChange={setSearchText}
        baseSearchCriteria={[{ filterKey: 'creator', value: 'SYSTEM', operation: SearchOperation.EQUAL }]}
        onStorySeriesClick={storySeries => router.navigate(paths.admin.series.editor + `?seriesId=${storySeries.id}`)}
      />

      {!isSearchFocused && !searchText && (
        <InfiniteScroll
          divider
          list={infiniteScrollStories}
          isLoading={isLoading}
          hasMore={currentPage !== totalPages - 1}
          loadMore={loadMore}
          renderListItem={element => (
            <AdminSeriesPreview
              storySeries={element}
              onStorySeriesClick={storySeries => router.navigate(paths.admin.series.editor + `?seriesId=${storySeries.id}`)}
            />
          )}
          renderEmptyListMessage={() => (
            <>
              <CustomImage directory="common" filename="library.png" width={pxToRem(62)} height={pxToRem(62)} />
              <Typography variant="h4">Brak bajek</Typography>
            </>
          )}
          renderLoadingRowItem={() => (
            <div className="d-flex justify-content-center p-4">
              <Typography variant="h4">Ładowanie...</Typography>
            </div>
          )}
        />
      )}
    </RouteLayout>
  );
};
