import { CustomImage } from 'features/ui/image/customImage';
import { RouteLayout } from 'features/ui/layout/routeLayout';
import _ from 'lodash';
import { useCallback, useEffect, useState } from 'react';
import { router } from 'routing/routes';
import paths from 'routing/utils';
import { InfiniteScroll } from 'shared/hooks/useInfiniteScroll';
import { Creator, StoryPreview } from 'shared/types/story';
import { pxToRem } from 'shared/utils/commonUtils';
import { Operator, SearchOperation, useSearchStoriesMutation } from 'store/api/endpoints/storyEndpoints';

import { Typography } from '../ui/typography/typography';
import { AdminStoryPreview } from './adminStoryPreview';
import { AdminStorySearch } from './adminStorySearch';

type Props = {
  onStoryClick: (story: StoryPreview) => void;
};

export const AdminStoryListRoute = () => {
  return (
    <RouteLayout backRoute={paths.admin.story.base}>
      <div className="my-5 mx-3">
        <AdminStoryList
          onStoryClick={story => router.navigate(paths.admin.story.editor + `?storyId=${story.storyId}&templateId=${story.templateId}`)}
        />
      </div>
    </RouteLayout>
  );
};

export const AdminStoryList = (props: Props) => {
  const [searchText, setSearchText] = useState('');
  const [isSearchFocused, setIsSearchFocused] = useState(false);
  const [allStories, setAllStories] = useState<StoryPreview[]>([]);
  const [totalPages, setTotalPages] = useState<number>(0);
  const [currentPage, setCurrentPage] = useState<number>(0);
  // rtk
  const [searchStories, { isLoading }] = useSearchStoriesMutation();

  const requestStories = useCallback(
    (page: number) => {
      searchStories({
        page: page,
        size: 10,
        searchRequest: {
          operator: Operator.AND,
          searchCriteriaGroups: [
            {
              groupOperator: Operator.AND,
              searchCriteria: [{ filterKey: 'creator', value: Creator.SYSTEM, operation: SearchOperation.EQUAL }]
            }
          ]
        }
      })
        .unwrap()
        .then(result => {
          setAllStories(prev => (page > 0 ? [...prev, ...result.content] : result.content));
          setTotalPages(result.totalPages);
        })
        .catch(() => {
          setAllStories([]);
          setTotalPages(0);
        });
    },
    [searchStories]
  );

  useEffect(() => {
    if (_.isEmpty(searchText)) {
      requestStories(0);
    }
  }, [requestStories, searchText]);

  const loadMore = () => {
    const nextPage = currentPage + 1;
    setCurrentPage(nextPage);
    requestStories(nextPage);
  };

  const infiniteScrollStories = allStories?.map(s => ({ ...s, id: s.storyId, label: s.title }));

  return (
    <>
      <Typography variant="h1" classNames="pb-3">
        Wyszukaj bajkę
      </Typography>

      <AdminStorySearch
        onSearchFocusChange={setIsSearchFocused}
        onSearchTextChange={setSearchText}
        baseSearchCriteria={[{ filterKey: 'creator', value: 'SYSTEM', operation: SearchOperation.EQUAL }]}
        onStoryClick={props.onStoryClick}
      />

      {!isSearchFocused && !searchText && (
        <InfiniteScroll
          divider
          list={infiniteScrollStories}
          isLoading={isLoading}
          hasMore={currentPage !== totalPages - 1}
          loadMore={loadMore}
          renderListItem={element => <AdminStoryPreview story={element} onStoryClick={props.onStoryClick} />}
          renderEmptyListMessage={() => (
            <>
              <CustomImage directory="common" filename="library.png" width={pxToRem(62)} height={pxToRem(62)} />
              <Typography variant="h4">Brak bajek</Typography>
            </>
          )}
          renderLoadingRowItem={() => (
            <div className="d-flex justify-content-center p-4">
              <Typography variant="h4">Ładowanie...</Typography>
            </div>
          )}
        />
      )}
    </>
  );
};
