import { DrawerModal, DrawerModalBody } from 'features/ui/drawer/drawer';
import { CustomImage, ThumbnailSize } from 'features/ui/image/customImage';
import { Typography } from 'features/ui/typography/typography';
import React, { useState } from 'react';
import { Button, Col, Container, Modal, Row } from 'react-bootstrap';
import { StoryPreview } from 'shared/types/story';
import { cGrey600 } from 'shared/utils/styleCommon';
import { useDeleteStoriesMutation } from 'store/api/endpoints/storyEndpoints';
import trashIcon from '../../shared/icons/trash_icon.svg';

type Props = {
  story: StoryPreview;
  searchText?: string;
  onStoryClick: (story: StoryPreview) => void;
};

export const AdminStoryPreview = (props: Props) => {
  const [isRemoveStoryModalOpen, setIsRemoveStoryModalOpen] = useState(false);
  const [deleteStory] = useDeleteStoriesMutation();

  const onDeleteStory = () => {
    deleteStory(props.story.storyId);
    window.location.reload();
  };

  return (
    <React.Fragment key={props.story.storyId}>
      <DrawerModal centered={false} show={isRemoveStoryModalOpen} onHide={() => setIsRemoveStoryModalOpen(false)}>
        <Container style={{ maxWidth: 'none' }}>
          <Row className="justify-content-sm-center">
            <Col xxl={6} xl={6} lg={6} md={6} sm={8} xs={12} style={{ position: 'relative' }} className="py-4 px-0">
              <Modal.Header closeButton></Modal.Header>
              <DrawerModalBody>
                <>
                  <div className="w-100 d-flex justify-content-center">
                    <div style={{ width: 100, height: 100 }}>
                      <img src={trashIcon} alt="" width={'100%'} />
                    </div>
                  </div>
                  <Typography variant="h1" classNames="pt-4 pb-1 text-center">
                    Czy napewno chcesz usunąć bajkę?
                  </Typography>
                  <div className="text-center">
                    <Typography variant="description" styles={{ color: cGrey600 }}>
                      Usunięcie bajki jest nieodwracalne. Czy napewno chcesz kontynuować?
                    </Typography>
                  </div>
                  <div className="d-flex flex-column align-items-center justify-content-center pt-5 mx-3 gap-3">
                    <Button className={'fullWidth'} variant="danger" onClick={onDeleteStory}>
                      Bezpowrotnie usuń bajkę
                    </Button>
                    <Button className={'fullWidth'} variant="outline-primary" onClick={() => setIsRemoveStoryModalOpen(false)}>
                      Wróć
                    </Button>
                  </div>
                </>
              </DrawerModalBody>
            </Col>
          </Row>
        </Container>
      </DrawerModal>
      <div className="d-flex justify-content-between align-items-center">
        <div role="button" onClick={() => props.onStoryClick(props.story)}>
          <div className="d-flex align-items-center gap-2">
            <CustomImage
              directory={'common'}
              filename={props.story.image}
              styles={{ borderRadius: '10px', flex: 'none' }}
              width={'100px'}
              height={'100px'}
              thumbnail={ThumbnailSize.THUMBNAIL_200X200}
              skeleton
              noImagePlaceholder
            />

            <div className="d-flex flex-column gap-2">{props.story.title}</div>
          </div>
        </div>
        <div style={{ width: '32px', height: '32px' }} onClick={() => setIsRemoveStoryModalOpen(true)} role="button">
          <img src={trashIcon} alt="" width={'100%'} />
        </div>
      </div>
    </React.Fragment>
  );
};
