import { Typography } from 'features/ui/typography/typography';
import { cGrey600 } from 'shared/utils/styleCommon';

export const TermsAndPrivacyLinks = () => (
  <div className="text-center">
    <Typography variant="description-md" inline styles={{ color: cGrey600 }}>
      Kontynuując akceptujesz{' '}
      <Typography
        variant="description-md"
        inline
        styles={{ textDecoration: 'underline', cursor: 'pointer' }}
        onClick={() => window.open('https://storytailo.com/regulamin', '_blank')}
      >
        Regulamin serwisu{' '}
      </Typography>
      <Typography variant="description-md" inline styles={{ color: cGrey600 }}>
        oraz{' '}
      </Typography>
      <Typography
        variant="description-md"
        inline
        styles={{ textDecoration: 'underline', cursor: 'pointer' }}
        onClick={() => window.open('https://storytailo.com/polityka-prywatnosci', '_blank')}
      >
        Politykę prywatności.
      </Typography>
    </Typography>
  </div>
);
