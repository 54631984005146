import { RouteLayout } from 'features/ui/layout/routeLayout';
import { OptionList } from 'features/ui/option-list/optionList';
import { router } from 'routing/routes';
import paths from 'routing/utils';

export const AdminPanel = () => {
  const options = [
    {
      action: () => router.navigate(paths.admin.story.base),
      label: 'Menadżer bajek'
    },
    {
      action: () => router.navigate(paths.admin.series.base),
      label: 'Menadżer serii'
    }
  ];

  return (
    <RouteLayout backRoute={paths.profile.base} hideBottomNavigation>
      <OptionList options={options} />
    </RouteLayout>
  );
};
