import { DrawerModal, DrawerModalBody } from 'features/ui/drawer/drawer';
import { Typography } from 'features/ui/typography/typography';
import { Button, Col, Container, Row } from 'react-bootstrap';
import { cGrey600, cSecondary } from 'shared/utils/styleCommon';
import tokenIcon from '../../../shared/icons/tokenIcon.svg';

type Props = {
  tokens: number;
  open: boolean;
  onClose: () => void;
};

export const PaymentSuccessModal = (props: Props) => {
  return (
    <DrawerModal centered={false} show={props.open} onHide={props.onClose}>
      <Container style={{ maxWidth: 'none' }}>
        <Row className="justify-content-sm-center">
          <Col xxl={6} xl={6} lg={6} md={6} sm={8} xs={12} style={{ position: 'relative' }} className="py-4 px-0">
            <DrawerModalBody>
              <div className="w-100 d-flex justify-content-center">
                <div style={{ width: 100, height: 100 }}>
                  <img src={tokenIcon} alt="" width={'100%'} />
                </div>
              </div>
              <Typography variant="h1" classNames="pt-3 pb-5 text-center">
                Sukces
              </Typography>

              <div className="text-center">
                <Typography variant="description" styles={{ color: cGrey600 }}>
                  Zakupiłeś{' '}
                  <Typography variant="description" styles={{ color: cSecondary }} inline>
                    +{props.tokens} Bajkowych Piór.{' '}
                  </Typography>
                  Możecie teraz dopasowywać i tworzyć Wasze nowe bajki.
                </Typography>
              </div>

              <div className="d-flex flex-column align-items-center justify-content-center pt-5 mx-3">
                <Button className="fullWidth" variant="outline-primary" onClick={props.onClose}>
                  Ok
                </Button>
              </div>
            </DrawerModalBody>
          </Col>
        </Row>
      </Container>
    </DrawerModal>
  );
};
