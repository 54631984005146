import { DefaultPaymentNotificationHandler } from 'features/payments/modals/handlers/defaultPaymentNotificationHandler';
import { RouteLayout } from 'features/ui/layout/routeLayout';
import { CURRENT_BALANCE_CARD_WIDTH, CurrentBalanceCard } from 'features/ui/payments/currentBalanceCard';
import { Typography } from 'features/ui/typography/typography';
import { useNavigate } from 'react-router-dom';
import paths from 'routing/utils';
import { ChildrenProfilesIcon } from 'shared/icons/childrenProfilesIcon';
import { HeroesIcon } from 'shared/icons/heroesIcon';
import { BottomMenuOptions } from './bottomMenuOptions';

export const UserProfile = () => {
  const navigate = useNavigate();

  return (
    <RouteLayout>
      <DefaultPaymentNotificationHandler />
      <div className="my-5 mx-3" style={{ position: 'relative' }}>
        <Typography variant="h1" classNames="my-4">
          Profil
        </Typography>

        <div style={{ width: CURRENT_BALANCE_CARD_WIDTH }}>
          <CurrentBalanceCard source={paths.profile.base} />

          <div className="my-5 d-flex justify-content-evenly align-items-center gap-5">
            <div
              className="d-flex flex-column justify-content-center align-items-center gap-2"
              role="button"
              onClick={() => navigate(paths.profile.childProfiles)}
            >
              <ChildrenProfilesIcon />
              <Typography variant="description-xs">Profile dzieci</Typography>
            </div>
            <div
              className="d-flex flex-column justify-content-center align-items-center gap-2"
              role="button"
              onClick={() => navigate(paths.profile.heroes)}
            >
              <HeroesIcon />
              <Typography variant="description-xs">Bohaterzy</Typography>
            </div>
          </div>
        </div>

        <div className="mt-5">
          <BottomMenuOptions />
        </div>
      </div>
    </RouteLayout>
  );
};
