import { StoryCostBadge } from 'features/ui/payments/storyCostBadge';
import { getTotalCost } from 'features/payments/utils.payments';
import { JustifyCenter } from 'features/ui/layout/justifyCenter';
import { RouteLayout } from 'features/ui/layout/routeLayout';
import { TagBadge } from 'features/ui/tags/tagBadge';
import { Typography } from 'features/ui/typography/typography';
import _ from 'lodash';
import { useEffect, useState } from 'react';
import { router } from 'routing/routes';
import paths from 'routing/utils';
import { PredefinedTag } from 'shared/types/predefinedTag';
import { useGetPredefinedTagsQuery } from 'store/api/endpoints/predefinedTagsEndpoint';
import { useAppDispatch, useAppSelector } from 'store/hooks';
import { setElements } from 'store/slices/storyCreatorSlice';

const defaultPredefinedTags: PredefinedTag[] = [];
const MAX_SELECTED_ELEMENTS = 5;

export const ElementsPicker = () => {
  const [selectedTags, setSelectedTags] = useState<PredefinedTag[]>([]);
  // redux
  const dispatch = useAppDispatch();
  const elements = useAppSelector(state => state.storyCreatorSlice.elements);
  const narrator = useAppSelector(state => state.storyCreatorSlice.narrator);
  // rtk
  const { data: predefinedTags = defaultPredefinedTags } = useGetPredefinedTagsQuery();

  const onTagClick = (tag: PredefinedTag) => {
    setSelectedTags(prev => {
      if (prev.find(pt => pt.id === tag.id)) {
        return prev.filter(pt => pt.id !== tag.id);
      } else {
        return [...prev, tag];
      }
    });
  };

  useEffect(() => {
    setSelectedTags(elements?.predefinedTags ?? []);
  }, [elements]);

  const totalCost = getTotalCost('custom', narrator);

  return (
    <RouteLayout
      backRoute={paths.storyCreator.custom.base}
      bottomActions={[
        {
          label: 'Zapisz',
          action: () => {
            dispatch(setElements(_.isEmpty(selectedTags) ? { random: true } : { random: false, predefinedTags: selectedTags }));
            router.navigate(paths.storyCreator.custom.base);
          }
        }
      ]}
      actions={[
        {
          icon: <StoryCostBadge cost={totalCost} />
        }
      ]}
    >
      <div className="pt-4 mx-3">
        <Typography variant="h1">Wybierz elementy, które powinny pojawić się bajce</Typography>
        <div className="d-flex justify-content-between align-items-center pt-4">
          <Typography variant="h4">Ilość elementów do wyboru</Typography>
          <Typography variant="h4">{MAX_SELECTED_ELEMENTS - selectedTags.length}</Typography>
        </div>

        <JustifyCenter classNames="gap-2 flex-wrap pt-4">
          {predefinedTags
            ?.filter((t: PredefinedTag): t is PredefinedTag & { id: string } => !_.isNil(t.id))
            .map(t => {
              const isActive = selectedTags.find(st => t.id === st.id) !== undefined;
              return (
                <TagBadge
                  key={t.id}
                  value={t.value}
                  isActive={isActive}
                  isDisabled={MAX_SELECTED_ELEMENTS - selectedTags.length === 0 && !isActive}
                  onClickCallback={() => onTagClick(t)}
                />
              );
            })}
        </JustifyCenter>
      </div>
    </RouteLayout>
  );
};
