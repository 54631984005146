import { Action, usePermission } from 'features/rbac/permissions';
import { DrawerModal, DrawerModalBody } from 'features/ui/drawer/drawer';
import { Loadable } from 'features/ui/loadable/loadable';
import { OptionList } from 'features/ui/option-list/optionList';
import { Typography } from 'features/ui/typography/typography';
import { useState } from 'react';
import { Button, Col, Container, Modal, Row } from 'react-bootstrap';
import { router } from 'routing/routes';
import paths from 'routing/utils';
import { AboutIcon } from 'shared/icons/aboutIcon';
import { FacebookIcon } from 'shared/icons/facebookIcon';
import { HelpIcon } from 'shared/icons/helpIcon';
import { LogoutIcon } from 'shared/icons/logoutIcon';
import { PaymentHistoryIcon } from 'shared/icons/paymentHistoryIcon';
import { SettingsIcon } from 'shared/icons/settingsIcon';
import { TermsIcon } from 'shared/icons/termsIcon';
import { AccountProvider } from 'shared/types/users';
import { cGrey600, cPrimary } from 'shared/utils/styleCommon';
import { useDeleteAccountMutation } from 'store/api/endpoints/accountEndpoints';
import { useAppDispatch, useAppSelector } from 'store/hooks';
import { addAlert } from 'store/slices/alertsSlice';
import buttonTrashIcon from '../../shared/icons/button_trash_icon.svg';
import trashIcon from '../../shared/icons/trash_icon.svg';
import trashPaddedIcon from '../../shared/icons/trash_padded_icon.svg';

export const BottomMenuOptions = () => {
  const [isConfirmationDialogOpen, setIsConfirmationDialogOpen] = useState<boolean>(false);
  // redux
  const loggedUser = useAppSelector(state => state.authSlice.loggedUser);
  const dispatch = useAppDispatch();
  // rtk
  const [deleteAccount, { isLoading }] = useDeleteAccountMutation();
  // other
  const { hasPermissionTo } = usePermission();

  const options = [
    {
      action: () => router.navigate(paths.admin.base),
      icon: <i className="bi bi-magic" style={{ padding: 8, color: cPrimary }}></i>,
      label: 'Panel admina',
      hidden: !hasPermissionTo(Action.ADMIN_PANEL)
    },
    {
      action: () => router.navigate(paths.payments.history),
      icon: <PaymentHistoryIcon />,
      label: 'Historia zakupów'
    },
    {
      action: () => window.open('https://www.facebook.com/storytailo', '_blank'),
      icon: <FacebookIcon />,
      label: 'Nowości i aktualizacje'
    },
    {
      action: () => router.navigate(paths.profile.settings.base),
      icon: <SettingsIcon />,
      label: 'Ustawienia',
      hidden: loggedUser?.provider !== AccountProvider.LOCAL
    },
    {
      action: () => window.open('mailto:komunikacja@storytailo.com', '_blank'),
      icon: <HelpIcon />,
      label: 'Pomoc'
    },
    {
      action: () => window.open('https://storytailo.com/', '_blank'),
      icon: <AboutIcon />,
      label: 'O aplikacji'
    },
    {
      action: () => window.open('https://storytailo.com/regulamin', '_blank'),
      icon: <TermsIcon />,
      label: 'Regulamin'
    },
    {
      action: () => setIsConfirmationDialogOpen(true),
      icon: <img src={trashPaddedIcon} alt="" />,
      label: 'Usuń konto'
    },
    {
      action: () => router.navigate(paths.logout),
      icon: <LogoutIcon />,
      label: 'Wyloguj się'
    }
  ];

  const onDeleteAccount = () => {
    loggedUser?.id &&
      deleteAccount(loggedUser.id)
        .unwrap()
        .then(() => {
          setIsConfirmationDialogOpen(false);
          router.navigate(paths.logout);
        })
        .catch(() => {
          setIsConfirmationDialogOpen(false);
          dispatch(addAlert({ color: 'danger', text: 'Nie udało się usunąć konta.' }));
        });
  };

  return (
    <>
      <DrawerModal centered={false} show={isConfirmationDialogOpen} onHide={() => setIsConfirmationDialogOpen(false)}>
        <Container style={{ maxWidth: 'none' }}>
          <Row className="justify-content-sm-center">
            <Col xxl={6} xl={6} lg={6} md={6} sm={8} xs={12} style={{ position: 'relative' }} className="py-4 px-0">
              <Modal.Header closeButton></Modal.Header>
              <DrawerModalBody>
                <Loadable loading={isLoading}>
                  <>
                    <div className="w-100 d-flex justify-content-center">
                      <div style={{ width: 100, height: 100 }}>
                        <img src={trashIcon} alt="" width={'100%'} />
                      </div>
                    </div>
                    <Typography variant="h1" classNames="pt-4 pb-1 text-center">
                      Czy napewno chcesz usunąć konto?
                    </Typography>
                    <div className="text-center">
                      <Typography variant="description" styles={{ color: cGrey600 }}>
                        Usunięcie konta jest nieodwracalne. Czy napewno chcesz kontynuować?
                      </Typography>
                    </div>
                    <div className="d-flex flex-column align-items-center justify-content-center pt-5 mx-3 gap-3">
                      <Button className={'fullWidth'} variant="danger" onClick={onDeleteAccount}>
                        <img src={buttonTrashIcon} alt="" style={{ paddingRight: 4 }} /> Bezpowrotnie usuń konto
                      </Button>
                      <Button className={'fullWidth'} variant="outline-primary" onClick={() => setIsConfirmationDialogOpen(false)}>
                        Wróć
                      </Button>
                    </div>
                  </>
                </Loadable>
              </DrawerModalBody>
            </Col>
          </Row>
        </Container>
      </DrawerModal>
      <OptionList options={options} />
    </>
  );
};
