import { CustomImage, ThumbnailSize } from 'features/ui/image/customImage';
import { StoryElementCostBadge } from 'features/ui/payments/storyElementCostBadge';
import { Card } from 'react-bootstrap';
import 'react-circular-progressbar/dist/styles.css';
import { ArrowRightIcon } from 'shared/icons/arrowRightIcon';

type Props = {
  action: () => void;
  filename?: string;
  directory?: string;
  children: React.ReactNode;
  tileId: string;
  cost: number;
};

export const ElementTile = (props: Props) => (
  <Card
    className="d-flex flex-row  justify-content-between p-2"
    style={{ boxShadow: '0px 4px 12px 0px rgba(0, 0, 0, 0.08)' }}
    onClick={props.action}
    role="button"
  >
    <div className="d-flex align-items-start justify-content-start gap-3" id={props.tileId}>
      <CustomImage
        directory={props.directory}
        filename={props.filename}
        width="75px"
        height="75px"
        styles={{ borderRadius: '6px' }}
        thumbnail={ThumbnailSize.THUMBNAIL_156X156}
        skeleton
        noImagePlaceholder
      />

      <div className="d-flex flex-column justify-content-start gap-1">{props.children}</div>
    </div>
    <div className="d-flex flex-column justify-content-between align-items-end">
      <ArrowRightIcon />
      {props.cost > 0 && <StoryElementCostBadge cost={props.cost} />}
    </div>
  </Card>
);
