import { StorySeries } from 'shared/types/series';
import { HTTP_METHODS, apiSlice } from '../api';
import { Page, SearchRequest } from './storyEndpoints';

const extendedApi = apiSlice.injectEndpoints({
  endpoints: builder => ({
    searchSeries: builder.mutation<Page<StorySeries>, { page: number; size: number; searchRequest: SearchRequest }>({
      query: data => ({
        url: `/public/series/search?page=${data.page}&size=${data.size}&sortField=lastModifiedDate`,
        method: HTTP_METHODS.POST,
        body: data.searchRequest
      })
    }),
    getSeriesById: builder.query<StorySeries, string>({
      query: id => ({
        url: `/public/series/${id}`,
        method: HTTP_METHODS.GET
      })
    }),
    createSeries: builder.mutation<StorySeries, StorySeries>({
      query: data => ({
        url: `/series`,
        method: HTTP_METHODS.POST,
        body: data
      })
    }),
    updateSeries: builder.mutation<StorySeries, StorySeries>({
      query: data => ({
        url: `/series`,
        method: HTTP_METHODS.PUT,
        body: data
      })
    }),
    uploadSeriesImage: builder.mutation<void, { seriesId: string; file: File }>({
      query: data => {
        const formData = new FormData();
        formData.append('file', data.file);

        return {
          url: `/series/${data.seriesId}/image`,
          method: HTTP_METHODS.PUT,
          body: formData
        };
      }
    }),
    deleteSeries: builder.mutation<void, string>({
      query: seriesId => ({
        url: `/series/${seriesId}`,
        method: HTTP_METHODS.DELETE
      })
    })
  }),
  overrideExisting: false
});

export const {
  useSearchSeriesMutation,
  useGetSeriesByIdQuery,
  useCreateSeriesMutation,
  useUpdateSeriesMutation,
  useUploadSeriesImageMutation,
  useDeleteSeriesMutation
} = extendedApi;
