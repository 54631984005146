import { router } from 'routing/routes';
import paths from 'routing/utils';
import { useHasSufficientSub } from 'shared/hooks/useHasSufficientSub';
import { Creator, StoryPreview } from 'shared/types/story';
import { pxToRem, rgba, toHHMMSS } from 'shared/utils/commonUtils';
import { cSecondary } from 'shared/utils/styleCommon';
import { CustomImage, ThumbnailSize } from '../image/customImage';
import { Typography } from '../typography/typography';

type Props = {
  story: StoryPreview;
};

export const VerticalStoryPreview = (props: Props) => {
  const requiredSubscription = props.story.readAccessLevel;
  const hasSufficientSub = useHasSufficientSub(requiredSubscription);

  return (
    <div
      style={{ width: pxToRem(160), opacity: hasSufficientSub ? 1 : 0.3 }}
      role="button"
      className="m-1"
      onClick={() => {
        hasSufficientSub
          ? router.navigate(paths.story + `/${props.story.storyId}`)
          : router.navigate(paths.subscription.base + `/${requiredSubscription}`);
      }}
    >
      <CustomImage
        directory={props.story.creator === Creator.SYSTEM || props.story.templateId ? 'common' : props.story.ownerId}
        filename={props.story.image}
        styles={{ borderRadius: '10px' }}
        height={'160px'}
        width={'160px'}
        thumbnail={ThumbnailSize.THUMBNAIL_360X360}
        skeleton
        noImagePlaceholder
      />

      <Typography classNames="pt-2" variant="description-md">
        {props.story.title}
      </Typography>
      {props.story.audioLength && (
        <Typography variant="description-sm" classNames="pt-1" styles={{ color: rgba(cSecondary, 0.6) }}>
          <i className="bi bi-clock pe-2"></i>
          {toHHMMSS(props.story.audioLength)}
        </Typography>
      )}
    </div>
  );
};
