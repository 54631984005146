import { Divider } from 'features/ui/divider/divider';
import { NoResult } from 'features/ui/search-story/noResult';
import { TagBadge } from 'features/ui/tags/tagBadge';
import { Typography } from 'features/ui/typography/typography';
import _ from 'lodash';
import { useEffect, useState } from 'react';
import { Form } from 'react-bootstrap';
import { InfiniteScroll } from 'shared/hooks/useInfiniteScroll';
import { StoryPreview } from 'shared/types/story';
import { Operator, SearchCriteria, SearchOperation, useSearchStoriesMutation } from 'store/api/endpoints/storyEndpoints';
import { useAppDispatch } from 'store/hooks';
import { addAlert } from 'store/slices/alertsSlice';
import { AdminStoryPreview } from './adminStoryPreview';

const PAGE_SIZE = 6;

type Props = {
  baseSearchCriteria?: SearchCriteria[];
  onSearchFocusChange: (isSearchFocused: boolean) => void;
  onSearchTextChange: (searchText: string) => void;
  onStoryClick: (storyPreview: StoryPreview) => void;
};

export const AdminStorySearch = (props: Props) => {
  const [searchText, setSearchText] = useState('');
  const [isSearchFocused, setIsSearchFocused] = useState(false);

  const [titleStories, setTitleStories] = useState<StoryPreview[]>([]);
  const [titleStoriesCount, setTitleStoriesCount] = useState<number>(0);
  const [titleStoriesTotalPages, setTitleStoriesTotalPages] = useState<number>(0);
  const [titleStoriesCurrentPage, setTitleStoriesCurrentPage] = useState<number>(0);

  //redux
  const dispatch = useAppDispatch();
  // rtk
  const [searchTitleStories, { isLoading: areTitleStoriesLoading }] = useSearchStoriesMutation();

  const { onSearchFocusChange, onSearchTextChange } = props;

  useEffect(() => {
    onSearchFocusChange(isSearchFocused);
  }, [onSearchFocusChange, isSearchFocused]);

  useEffect(() => {
    onSearchTextChange(searchText);
  }, [onSearchTextChange, searchText]);

  const requestTitleStories = (page: number, searchCriteria: SearchCriteria[]) => {
    searchTitleStories({
      page: page,
      size: PAGE_SIZE,
      searchRequest: {
        operator: Operator.AND,
        searchCriteriaGroups: [
          { groupOperator: Operator.AND, searchCriteria: [...(props.baseSearchCriteria ?? [])] },
          { groupOperator: Operator.AND, searchCriteria: searchCriteria }
        ]
      }
    })
      .unwrap()
      .then(result => {
        setTitleStories(prev => (page > 0 ? [...prev, ...result.content] : result.content));
        setTitleStoriesCount(result.totalElements);
        setTitleStoriesTotalPages(result.totalPages);
      })
      .catch(() => dispatch(addAlert({ color: 'danger', text: 'Nie udało się wyszukać bajek po tytule' })));
  };

  const onSearch = (event: React.ChangeEvent<HTMLInputElement>) => {
    const value = event.target.value;
    setSearchText(value);
    const currentPage = 0;
    setTitleStoriesCurrentPage(currentPage);

    if (_.isEmpty(value)) {
      setTitleStories([]);
      setTitleStoriesCount(0);
      return;
    }

    const titleCriteria = {
      filterKey: 'title',
      value: value,
      operation: SearchOperation.CONTAINS
    };

    requestTitleStories(currentPage, [titleCriteria]);
  };

  const loadMore = () => {
    const titleCriteria = {
      filterKey: 'title',
      value: searchText,
      operation: SearchOperation.CONTAINS
    };

    const nextPage = titleStoriesCurrentPage + 1;
    setTitleStoriesCurrentPage(nextPage);
    requestTitleStories(nextPage, [titleCriteria]);
  };
  let stories: StoryPreview[] = [];
  let totalPages: number = 0;
  let currentPage: number = 0;
  stories = titleStories;
  totalPages = titleStoriesTotalPages;
  currentPage = titleStoriesCurrentPage;

  const isLoading = areTitleStoriesLoading;

  const isNoResult = !isLoading && !_.isEmpty(searchText) && _.isEmpty(stories);

  const infiniteScrollStories = stories.map(s => ({ ...s, id: s.storyId, label: s.title }));

  return (
    <>
      <Form.Group>
        <span className="fa fa-search form-control-feedback"></span>
        <Form.Control
          type="text"
          placeholder="Czego szukasz?"
          onChange={onSearch}
          onFocus={() => setIsSearchFocused(true)}
          onBlur={() => setIsSearchFocused(false)}
        />
      </Form.Group>

      <Divider spacing="my-4" />

      {(isSearchFocused || !_.isEmpty(searchText)) && (
        <>
          {!_.isEmpty(searchText) && (
            <div className="d-flex flex-wrap gap-2 pb-4">
              <TagBadge value={`Tytuł (${titleStoriesCount})`} isActive={true} onClickCallback={_.noop} />
            </div>
          )}
          <div className="d-flex flex-column">
            <InfiniteScroll
              divider
              list={infiniteScrollStories}
              isLoading={isLoading}
              hasMore={currentPage !== totalPages - 1}
              loadMore={loadMore}
              renderListItem={element => <AdminStoryPreview story={element} onStoryClick={props.onStoryClick} />}
              renderEmptyListMessage={() => (isNoResult ? <NoResult /> : <></>)}
              renderLoadingRowItem={() => (
                <div className="d-flex justify-content-center p-4">
                  <Typography variant="h4">Ładowanie...</Typography>
                </div>
              )}
            />
          </div>
        </>
      )}
    </>
  );
};
