import { Divider } from 'features/ui/divider/divider';
import { RouteLayout } from 'features/ui/layout/routeLayout';
import { Typography } from 'features/ui/typography/typography';
import { Button } from 'react-bootstrap';
import { useNavigate } from 'react-router-dom';
import paths from 'routing/utils';
import { cGrey600, cPrimary } from 'shared/utils/styleCommon';
import mailOutlineIcon from '../../shared/icons/mail_outline.svg';
import { GoogleButton } from './googleButton';
import { HeaderStorytailo } from './headerStorytailo';
import { TermsAndPrivacyLinks } from './termsAndPrivacyLinks';

export const SignIn = (): JSX.Element => {
  const navigate = useNavigate();

  return (
    <RouteLayout>
      <div className="d-flex flex-column justify-content-between h-100 pb-4">
        <div>
          <HeaderStorytailo headerText="Zaloguj się" />
          <div className="d-flex flex-column align-items-center gap-3 mx-4 mb-4 " style={{ paddingTop: 32 }}>
            <GoogleButton label="Zaloguj z Google" />

            <Button
              variant="text-primary fullWidth"
              className="long d-flex align-items-center gap-2"
              onClick={() => navigate(paths.signIn.email)}
            >
              <img src={mailOutlineIcon} alt="" />
              Zaloguj się przez email
            </Button>

            <Divider spacing={'my-2'} />
            <div className="d-flex flex-column align-items-center mx-4 mb-4">
              <Typography variant="description-md" styles={{ color: cGrey600 }}>
                Nie masz jeszcze konta?
              </Typography>
              <Typography variant="h5" styles={{ color: cPrimary, cursor: 'pointer' }} onClick={() => navigate(paths.signUp.options)}>
                Zarejestruj się
              </Typography>
            </div>
          </div>
        </div>

        <TermsAndPrivacyLinks />
      </div>
    </RouteLayout>
  );
};
