import React from 'react';
import { pxToRem } from 'shared/utils/commonUtils';
import { Divider } from '../divider/divider';
import { Typography } from '../typography/typography';

export interface Option {
  icon?: JSX.Element;
  label: string;
  action: () => void;
  hidden?: boolean;
}

type Props = {
  options: Option[];
};

export const OptionList = (props: Props) => {
  return (
    <>
      {props.options
        .filter(o => !o.hidden)
        .map((o, index) => (
          <React.Fragment key={index}>
            <div
              className="d-flex align-items-center justify-content-between gap-2 py-3 ps-1"
              style={{ borderLeft: ' none', borderRight: 'none' }}
              role="button"
              onClick={() => o.action()}
            >
              <div className="d-flex align-items-center gap-2">
                {o.icon}
                <Typography variant="description">{o.label}</Typography>
              </div>

              <i style={{ fontSize: pxToRem(18) }} className="bi bi-chevron-right"></i>
            </div>
            {index !== props.options.length - 1 && <Divider spacing="my-1" />}
          </React.Fragment>
        ))}
    </>
  );
};
