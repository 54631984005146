import _ from 'lodash';
import { useCallback } from 'react';
import { AuthorityType } from 'shared/types/account';
import { useAppSelector } from 'store/hooks';

export enum Action {
  BASE = 'BASE',
  ADMIN_PANEL = 'ADMIN_PANEL'
}

const permissions: {
  [key in AuthorityType]?: {
    actions: Action[];
  };
} = {
  [AuthorityType.ADMIN]: {
    actions: [Action.BASE, Action.ADMIN_PANEL]
  },
  [AuthorityType.USER]: {
    actions: [Action.BASE]
  }
};

export const usePermission = () => {
  const loggedUser = useAppSelector(state => state.authSlice.loggedUser);

  const hasPermissionTo = useCallback(
    (action: Action) =>
      loggedUser?.authorities?.some((authority: AuthorityType) => {
        return permissions[authority]?.actions.includes(action);
      }) ?? false,
    [loggedUser]
  );

  return {
    isLogged: !_.isNil(loggedUser),
    loggedUser,
    hasPermissionTo
  };
};
