import { PayloadAction, createSlice } from '@reduxjs/toolkit';
import { loadState } from 'store/utils';

const SLICE_NAME = 'paymentSlice';

type PaymentInfo = { status: 'ERROR' | 'SUCCESS'; tokens?: number } | null;

interface PaymentState {
  paymentInfo: PaymentInfo;
  source: string | null;
}

const initialState: PaymentState = {
  paymentInfo: null,
  source: null
};

const persistedState = loadState<PaymentState>(SLICE_NAME);

export const paymentSlice = createSlice({
  name: SLICE_NAME,
  initialState: persistedState ?? initialState,
  reducers: {
    setPaymentInfo(state, action: PayloadAction<PaymentInfo>) {
      state.paymentInfo = action.payload;
    },
    setSource(state, action: PayloadAction<string>) {
      state.source = action.payload;
    },
    resetPaymentState() {
      return initialState;
    }
  }
});

export const { setPaymentInfo, resetPaymentState, setSource } = paymentSlice.actions;

export default paymentSlice.reducer;
