import { PickerItemPreview } from 'features/story-creator/pickers/pickerItemPreview';
import { Typography } from 'features/ui/typography/typography';
import _ from 'lodash';
import { PredefinedNarrator } from 'shared/types/narrator';
import { useGetPredefinedNarratorsQuery } from 'store/api/endpoints/predefinedNarratorsEndpoints';

const defaultPredefinedNarrators: PredefinedNarrator[] = [];

type Props = {
  selectedNarrator: PredefinedNarrator | null;
  setSelectedNarratorCallback: (pn: PredefinedNarrator) => void;
};

export const AdminNarratorPicker = (props: Props) => {
  const { data: predefinedNarrators = defaultPredefinedNarrators } = useGetPredefinedNarratorsQuery();

  return (
    <div>
      <Typography variant="h1" classNames="mb-5">
        Lektor
      </Typography>
      <div className="d-flex align-items-start gap-3 mt-2 flex-wrap">
        {_.sortBy(predefinedNarrators, 'order').map(pn => (
          <div key={pn.id}>
            <PickerItemPreview
              isSelected={props.selectedNarrator?.id === pn.id}
              filename={pn.image}
              directory={'common'}
              name={pn.value}
              cost={pn.cost}
              onClickCallback={() => {
                props.setSelectedNarratorCallback(pn);
              }}
            />
          </div>
        ))}
      </div>
    </div>
  );
};
