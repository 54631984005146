import { CustomImage } from 'features/ui/image/customImage';
import { Typography } from 'features/ui/typography/typography';
import { Button } from 'react-bootstrap';
import { useNavigate } from 'react-router-dom';
import paths from 'routing/utils';
import { pxToRem } from 'shared/utils/commonUtils';
import { borderMd, cWhite } from 'shared/utils/styleCommon';

export const CustomStoryBanner = () => {
  const navigate = useNavigate();

  return (
    <div
      className="w-100 h-100"
      style={{
        borderRadius: borderMd,
        maxHeight: pxToRem(190),
        zIndex: 0,
        overflow: 'hidden',
        position: 'relative'
      }}
    >
      <CustomImage directory="common" filename="baner_promo_discover.png" skeleton width="100%" />
      <div
        className="d-flex flex-column justify-content-center align-items-center h-100 p-2"
        style={{ position: 'absolute', zIndex: 1, width: '100%', top: 0 }}
      >
        <Typography variant="h1" classNames="text-center pb-2" styles={{ color: cWhite, fontSize: 24 }}>
          Wasza własna bajka
        </Typography>
        <Typography variant="description" classNames="text-center pb-3 px-3" styles={{ color: cWhite }}>
          Wybierajcie bohaterów, światy, morały i wiele więcej.
        </Typography>
        <Button
          style={{ paddingTop: 10, paddingBottom: 10, paddingRight: 30, paddingLeft: 30 }}
          onClick={() => navigate(paths.storyCreator.custom.base)}
        >
          Stwórzmy bajkę
        </Button>
      </div>
    </div>
  );
};
