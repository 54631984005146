import { RouteLayout } from 'features/ui/layout/routeLayout';
import { OptionList } from 'features/ui/option-list/optionList';
import { router } from 'routing/routes';
import paths from 'routing/utils';

export const AdminSeriesManager = () => {
  const options = [
    {
      action: () => router.navigate(paths.admin.series.editor),
      label: 'Dodaj nową serię'
    },
    {
      action: () => router.navigate(paths.admin.series.list),
      label: 'Modyfikuj istniejącą'
    }
  ];

  return (
    <RouteLayout backRoute={paths.admin.base} hideBottomNavigation>
      <OptionList options={options} />
    </RouteLayout>
  );
};
