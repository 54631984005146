import { DefaultPaymentNotificationHandler } from 'features/payments/modals/handlers/defaultPaymentNotificationHandler';
import { CustomImage } from 'features/ui/image/customImage';
import { JustifyCenter } from 'features/ui/layout/justifyCenter';
import { RouteLayout } from 'features/ui/layout/routeLayout';
import { VerticalStoryPreview } from 'features/ui/story-preview/verticalStoryPreview';
import { Typography } from 'features/ui/typography/typography';
import _ from 'lodash';
import { router } from 'routing/routes';
import paths from 'routing/utils';
import { SubscriptionType } from 'shared/types/users';
import { useGetHomeScreenDataQuery } from 'store/api/endpoints/homeScreenEndpoints';
import { useAppSelector } from 'store/hooks';
import homeHeaderSpiralImg from '../../shared/images/home-header-spiral.png';
import storytailo from '../../shared/images/storytailo.svg';
import { Carousel } from '../ui/carousel/carousel';
import { SeriesPreview } from '../ui/series-preview/seriesPreview';
import { AdvertBanner } from './banner/advertBanner';
import { CustomStoryBanner } from './banner/customStoryBanner';
import { SeriesBanner } from './banner/seriesBanner';
import { TokenFloatingButton } from './tokenFloatingButton';

export const Home = () => {
  // redux
  const loggedUser = useAppSelector(state => state.authSlice.loggedUser);
  // rtk
  const { data } = useGetHomeScreenDataQuery();

  if (!data) {
    return;
  }

  const { standardStories, highlightedStories, pegiStories, standardSeries, highlightedSeries } = data;

  const username = loggedUser?.firstName;

  return (
    <RouteLayout>
      <DefaultPaymentNotificationHandler />
      {!!loggedUser && <TokenFloatingButton tokens={loggedUser.tokens} />}

      <div className="d-flex flex-column gap-3 mx-3 mb-4" style={{ position: 'relative', overflow: 'hidden', paddingTop: 32 }}>
        <img src={homeHeaderSpiralImg} alt="" style={{ position: 'absolute', bottom: 0 }} />
        <img src={storytailo} alt="" width="100px" className="pb-4" />
        <Typography variant="description-lg">
          Cześć{username ? ` ${username}` : ''},<Typography variant="h1">co dziś czytamy?</Typography>
        </Typography>
      </div>
      <div>
        {!_.isEmpty(standardStories) && (
          <div className="mb-4 mx-3">
            <Carousel items={standardStories ?? []} renderItem={s => <VerticalStoryPreview story={s} key={s.storyId} />} />
          </div>
        )}

        {!_.isEmpty(highlightedSeries) && (
          <JustifyCenter classNames="mb-5">
            <SeriesBanner storySeries={highlightedSeries[0]} />
          </JustifyCenter>
        )}

        <div className="mb-5 mx-3">
          <CustomStoryBanner />
        </div>

        {!_.isEmpty(standardSeries) && (
          <div className="mb-5 mx-3">
            <Typography variant="h1">Zobacz serie bajek</Typography>
            <Typography variant="description" classNames="mb-4">
              Więcej niż jeden odcinek
            </Typography>

            <Carousel
              items={standardSeries}
              renderItem={s => (
                <SeriesPreview
                  storySeries={s}
                  key={s.id}
                  onStorySeriesClick={storySeries => router.navigate(paths.series + `/${storySeries.id}`)}
                />
              )}
            />
          </div>
        )}

        {(!loggedUser || loggedUser.subscription?.type === SubscriptionType.FREE) && (
          <JustifyCenter classNames="mb-5 px-3">
            <AdvertBanner />
          </JustifyCenter>
        )}

        {!_.isEmpty(highlightedStories) && (
          <div style={{ background: 'linear-gradient(#FDF0DD, #FDF0DD, #FDF0DD, #D2BE97)', position: 'relative' }} className="p-3 mb-5">
            <CustomImage
              directory="common"
              filename="spiral3.png"
              styles={{
                position: 'absolute',
                zIndex: 0,
                right: 0,
                top: 0,
                overflow: 'hidden',
                opacity: 0.8
              }}
            />
            <Typography variant="h1">Może Ci się spodobać</Typography>
            <Typography variant="description" classNames="mb-4">
              Bajki dostosowane do Ciebie
            </Typography>

            <Carousel items={highlightedStories} renderItem={s => <VerticalStoryPreview story={s} key={s.storyId} />} />
          </div>
        )}

        {!_.isEmpty(pegiStories) &&
          pegiStories.map(ps => {
            if (_.isEmpty(ps.stories)) {
              return <></>;
            }
            return (
              <div className="mb-5 mx-3" key={ps.age}>
                <Typography variant="h1" classNames="mb-4">
                  Idealne dla {ps.age} latka
                </Typography>

                <Carousel items={ps.stories} renderItem={s => <VerticalStoryPreview story={s} key={s.storyId} />} />
              </div>
            );
          })}
      </div>
    </RouteLayout>
  );
};
