import { GiftModal } from 'giftModal';
import { useEffect, useRef, useState } from 'react';
import { Outlet, useLocation } from 'react-router-dom';
import paths from 'routing/utils';
import { useAnalytics } from 'shared/hooks/useAnalytics';
import { useAppDispatch, useAppSelector } from 'store/hooks';
import { alertsSliceReset } from 'store/slices/alertsSlice';

const GIFT_MODAL_EXCLUDED_LOCATIONS = [
  paths.signIn.email,
  paths.signIn.options,
  paths.signUp.email,
  paths.signUp.options,
  paths.resetPassword,
  paths.activate,
  paths.newPassword,
  paths.oauthSuccess,
  paths.oauthFailure,
  paths.newPasswordSuccess,
  paths.resetPasswordSuccess,
  paths.signUpSuccess,
  paths.logout
];

export const Dashboard = () => {
  const [wasGiftModalShown, setWasGiftModalShown] = useState<boolean>(false);
  const [isGiftModalOpen, setIsGiftModalOpen] = useState<boolean>(false);
  const timer = useRef<NodeJS.Timeout>();
  // redux
  const dispatch = useAppDispatch();
  const loggedUser = useAppSelector(state => state.authSlice.loggedUser);
  // other
  const { pathname } = useLocation();
  useAnalytics();

  useEffect(() => {
    const isInModalExcludedLocation = GIFT_MODAL_EXCLUDED_LOCATIONS.some(exLoc => pathname.endsWith(exLoc));
    if (loggedUser || isInModalExcludedLocation) {
      if (loggedUser) {
        setWasGiftModalShown(true);
      }
      clearTimeout(timer.current);
      timer.current = undefined;
    } else {
      if (!isInModalExcludedLocation && !wasGiftModalShown) {
        if (!timer.current) {
          timer.current = setTimeout(() => {
            setIsGiftModalOpen(true);
            setWasGiftModalShown(true);
          }, 5000);
        }
      }
    }
  }, [loggedUser, pathname, wasGiftModalShown]);

  useEffect(() => {
    dispatch(alertsSliceReset());
  }, [pathname, dispatch]);

  return (
    <div className="d-flex flex-column h-100 justify-content-between">
      <GiftModal open={isGiftModalOpen} onClose={() => setIsGiftModalOpen(false)} />
      <Outlet />
    </div>
  );
};
