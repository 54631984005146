import { Button } from 'react-bootstrap';
import { GoogleIcon } from 'shared/icons/googleIcon';
import { API_URL } from 'store/api/api';

type Props = {
  label: string;
};

export const GoogleButton = (props: Props) => {
  const getSocialLoginUrl = (provider: string) => {
    window.open(`${API_URL}/oauth2/authorization/${provider}?redirect_uri=${process.env.REACT_APP_OAUTH2_REDIRECT_URL}`, '_self');
  };

  return (
    <Button className="fullWidth gap-2" onClick={() => getSocialLoginUrl('google')} style={{ paddingTop: 10, paddingBottom: 10 }}>
      <GoogleIcon />
      {props.label}
    </Button>
  );
};
