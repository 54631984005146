import { Divider } from 'features/ui/divider/divider';
import { DrawerModal, DrawerModalBody } from 'features/ui/drawer/drawer';
import { Typography } from 'features/ui/typography/typography';
import { Button, Col, Container, Row } from 'react-bootstrap';
import { cGrey600, cWhite } from 'shared/utils/styleCommon';
import { useAppSelector } from 'store/hooks';
import arrowRightIcon from '../../shared/icons/arrowRightIcon.svg';
import tokenIcon from '../../shared/icons/tokenIcon.svg';

type Props = {
  totalCost: number;
  open: boolean;
  onClose: () => void;
  onAccept: () => void;
};

export const InsufficientTokensModal = (props: Props) => {
  // redux
  const loggedUser = useAppSelector(state => state.authSlice.loggedUser);

  return (
    <DrawerModal centered={false} show={props.open} onHide={props.onClose}>
      <Container style={{ maxWidth: 'none' }}>
        <Row className="justify-content-sm-center">
          <Col xxl={6} xl={6} lg={6} md={6} sm={8} xs={12} style={{ position: 'relative' }} className="py-4 px-0">
            <DrawerModalBody>
              <div className="w-100 d-flex justify-content-center">
                <div style={{ width: 80, height: 80 }}>
                  <img src={tokenIcon} alt="" width={'100%'} />
                </div>
              </div>
              <Typography variant="h1" classNames="pt-3 pb-5 text-center">
                Autor potrzebuje mocy Baśnowych Piór
              </Typography>

              <div className="d-flex justify-content-between">
                <Typography variant="description" styles={{ color: cGrey600 }}>
                  Twoje Baśniowe Pióra:
                </Typography>
                <Typography variant="description" styles={{ color: cGrey600 }}>
                  {loggedUser?.tokens}
                </Typography>
              </div>
              <Divider spacing={'my-2'} />
              <div className="d-flex justify-content-between">
                <Typography variant="description" styles={{ color: cGrey600 }}>
                  Koszt bajki:
                </Typography>
                <Typography variant="description" styles={{ color: cGrey600 }}>
                  {props.totalCost}
                </Typography>
              </div>

              <div className="d-flex flex-column align-items-center justify-content-center gap-3 pt-5 mx-3">
                <Button
                  className="fullWidth d-flex align-items-center justify-content-center gap-2"
                  variant="secondary"
                  onClick={props.onAccept}
                >
                  <Typography variant="h5" styles={{ color: cWhite }}>
                    Doładuj konto i generuj
                  </Typography>

                  <img src={arrowRightIcon} alt="" style={{ width: 22, height: 22 }} />
                </Button>
                <Button className="fullWidth" variant="outline-primary" onClick={props.onClose}>
                  Powrót do edycji
                </Button>
              </div>
            </DrawerModalBody>
          </Col>
        </Row>
      </Container>
    </DrawerModal>
  );
};
