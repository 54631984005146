import { StoryCostBadge } from 'features/ui/payments/storyCostBadge';
import { getTotalCost } from 'features/payments/utils.payments';
import { RouteLayout } from 'features/ui/layout/routeLayout';
import { SelectableItem } from 'features/ui/selectable/selectableItem';
import { Typography } from 'features/ui/typography/typography';
import { useEffect, useState } from 'react';
import { router } from 'routing/routes';
import paths from 'routing/utils';
import { ShortStoryIcon } from 'shared/icons/shortStoryIcon';
import { StoryLength } from 'shared/types/story';
import { useAppDispatch, useAppSelector } from 'store/hooks';
import { setStoryLength } from 'store/slices/storyCreatorSlice';

export const LengthPicker = () => {
  const [selectedLength, setSelectedLength] = useState<StoryLength>(StoryLength.SHORT);
  // redux
  const dispatch = useAppDispatch();
  const storyLength = useAppSelector(state => state.storyCreatorSlice.storyLength);
  const narrator = useAppSelector(state => state.storyCreatorSlice.narrator);

  useEffect(() => {
    setSelectedLength(storyLength ?? StoryLength.SHORT);
  }, [storyLength]);

  const totalCost = getTotalCost('custom', narrator);

  return (
    <RouteLayout
      backRoute={paths.storyCreator.custom.base}
      bottomActions={[
        {
          label: 'Zapisz',
          action: () => {
            dispatch(setStoryLength(selectedLength));
            router.navigate(paths.storyCreator.custom.base);
          }
        }
      ]}
      actions={[
        {
          icon: <StoryCostBadge cost={totalCost} />
        }
      ]}
    >
      <div className="pt-4 mx-3">
        <Typography variant="h1">Długość bajki</Typography>

        <div className="d-flex flex-column w-100 pt-5 pb-5 gap-3">
          <SelectableItem
            onClickCallback={() => setSelectedLength(StoryLength.SHORT)}
            topText="Bajka średniej długości"
            bottomText="około 4-6 min"
            isSelected={selectedLength === StoryLength.SHORT}
            icon={<ShortStoryIcon />}
          />
          {/* <SelectableItem
            onClickCallback={() => setSelectedLength(StoryLength.LONG)}
            topText="Długa"
            bottomText="5 - 7:30 min"
            isSelected={selectedLength === StoryLength.LONG}
            icon={<LongStoryIcon />}
          /> */}
        </div>
      </div>
    </RouteLayout>
  );
};
