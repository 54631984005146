export interface Transaction {
  id: string;
  userId: string;
  productId: string;
  status: TransactionStatus;
}

export enum TransactionStatus {
  CREATED = 'CREATED',
  PAYMENT_PROCESS_INITIALIZED = 'PAYMENT_PROCESS_INITIALIZED',
  PAYED_NOT_FULFILLED = 'PAYED_NOT_FULFILLED',
  FULFILLED = 'FULFILLED',
  FULFILLED_OVERPAY = 'FULFILLED_OVERPAY',
  FULFILLED_UNDERPAY = 'FULFILLED_UNDERPAY',
  CHARGEBACK = 'CHARGEBACK'
}
