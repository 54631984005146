import { DrawerModal, DrawerModalBody } from 'features/ui/drawer/drawer';
import { CustomImage } from 'features/ui/image/customImage';
import { Typography } from 'features/ui/typography/typography';
import { useState } from 'react';
import { Button, Col, Container, Modal, Row } from 'react-bootstrap';
import { StorySeries } from 'shared/types/series';
import { rgba } from 'shared/utils/commonUtils';
import { cGrey600, cWhite } from 'shared/utils/styleCommon';
import { useDeleteSeriesMutation } from 'store/api/endpoints/seriesEndpoints';
import trashIcon from '../../shared/icons/trash_icon.svg';

type Props = {
  storySeries: StorySeries;
  onStorySeriesClick: (storySeries: StorySeries) => void;
};

function inflect(count: number): string {
  if (count === 1) {
    return 'odcinek';
  } else if (count >= 2 && count <= 4) {
    return 'odcinki';
  } else {
    return 'odcinków';
  }
}

export const AdminSeriesPreview = (props: Props) => {
  const [isRemoveSeriesModalOpen, setIsRemoveSeriesModalOpen] = useState(false);
  const [seriesInDeletionContext, setSeriesInDeletionContext] = useState<string | null>(null);
  // rtk
  const [deleteSeries] = useDeleteSeriesMutation();

  const onDeleteSeries = () => {
    if (seriesInDeletionContext) {
      deleteSeries(seriesInDeletionContext);
      window.location.reload();
    }
  };

  const episodesSize = props.storySeries.episodes.length;

  return (
    <>
      <DrawerModal
        centered={false}
        show={isRemoveSeriesModalOpen}
        onHide={() => {
          setIsRemoveSeriesModalOpen(false);
          setSeriesInDeletionContext(null);
        }}
      >
        <Container style={{ maxWidth: 'none' }}>
          <Row className="justify-content-sm-center">
            <Col xxl={6} xl={6} lg={6} md={6} sm={8} xs={12} style={{ position: 'relative' }} className="py-4 px-0">
              <Modal.Header closeButton></Modal.Header>
              <DrawerModalBody>
                <>
                  <div className="w-100 d-flex justify-content-center">
                    <div style={{ width: 100, height: 100 }}>
                      <img src={trashIcon} alt="" width={'100%'} />
                    </div>
                  </div>
                  <Typography variant="h1" classNames="pt-4 pb-1 text-center">
                    Czy napewno chcesz usunąć serię?
                  </Typography>
                  <div className="text-center">
                    <Typography variant="description" styles={{ color: cGrey600 }}>
                      Usunięcie serii jest nieodwracalne. Czy napewno chcesz kontynuować?
                    </Typography>
                  </div>
                  <div className="d-flex flex-column align-items-center justify-content-center pt-5 mx-3 gap-3">
                    <Button className={'fullWidth'} variant="danger" onClick={onDeleteSeries}>
                      Bezpowrotnie usuń serię
                    </Button>
                    <Button
                      className={'fullWidth'}
                      variant="outline-primary"
                      onClick={() => {
                        setIsRemoveSeriesModalOpen(false);
                        setSeriesInDeletionContext(null);
                      }}
                    >
                      Wróć
                    </Button>
                  </div>
                </>
              </DrawerModalBody>
            </Col>
          </Row>
        </Container>
      </DrawerModal>
      <div className="d-flex justify-content-between align-items-center">
        <div
          className="m-1"
          role="button"
          style={{
            position: 'relative',
            borderRadius: '10px',
            overflow: 'hidden',
            width: 218,
            height: 260
          }}
          onClick={() => props.onStorySeriesClick(props.storySeries)}
        >
          <CustomImage
            directory="common"
            filename={props.storySeries.image}
            styles={{ position: 'relative', borderRadius: '10px', zIndex: 1 }}
            height={'260px'}
            width={'218px'}
            skeleton
          />
          <CustomImage
            directory="common"
            filename={'series_dark_layer.png'}
            styles={{ position: 'absolute', top: 0, left: 0, borderRadius: '10px', zIndex: 2 }}
            width={'218px'}
            skeleton
          />
          <CustomImage
            directory="common"
            filename={'blue_cloud.png'}
            styles={{ position: 'absolute', bottom: 0, left: 0, zIndex: 3 }}
            width={'218px'}
          />

          <div
            className="d-flex flex-column justify-content-end align-items-start h-100 p-3"
            style={{ position: 'absolute', bottom: 0, left: 0, zIndex: 4 }}
          >
            <Typography classNames="pt-2" variant="h5" styles={{ color: cWhite }}>
              {props.storySeries.title}
            </Typography>
            <Typography variant="description-sm" classNames="pt-1" styles={{ color: rgba(cWhite, 0.6) }}>
              {episodesSize} {inflect(episodesSize)}
            </Typography>
          </div>
        </div>
        <div
          style={{ width: '32px', height: '32px' }}
          onClick={() => {
            setIsRemoveSeriesModalOpen(true);
            props.storySeries.id && setSeriesInDeletionContext(props.storySeries.id);
          }}
          role="button"
        >
          <img src={trashIcon} alt="" width={'100%'} />
        </div>
      </div>
    </>
  );
};
