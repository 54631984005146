import { Typography } from 'features/ui/typography/typography';
import { Button } from 'react-bootstrap';
import { router } from 'routing/routes';
import paths from 'routing/utils';
import { useHasSufficientSub } from 'shared/hooks/useHasSufficientSub';
import { Creator, Story } from 'shared/types/story';
import { cGrey200 } from 'shared/utils/styleCommon';
import { useAppDispatch } from 'store/hooks';
import { setTemplateId, setTemplateStoryId } from 'store/slices/storyCreatorSlice';
import { BlurOverlay } from './blurOverlay';
import { IPSUM } from './ipsum';

type Props = {
  story?: Story;
  onClose: () => void;
};

export const StoryOverlay = (props: Props) => {
  // redux
  const dispatch = useAppDispatch();
  // other
  const requiredSubscription = props.story?.customizeAccessLevel;
  const hasSufficientSub = useHasSufficientSub(requiredSubscription);

  return (
    <div className="w-100" style={{ position: 'relative', overflow: 'hidden' }}>
      <div style={{ position: 'relative' }}>
        <BlurOverlay></BlurOverlay>
        <div className="d-flex flex-column gap-3 align-items-center w-100" style={{ marginTop: 64, zIndex: 100, position: 'absolute' }}>
          <Typography variant="h1" classNames="text-center">
            Czy chcesz dodać kilka rzeczy do Twojej bajki?
          </Typography>
          <Button variant="primary" onClick={props.onClose}>
            Czytamy
          </Button>
          <Button
            variant="outline-primary"
            onClick={() => {
              if (props.story?.creator === Creator.SYSTEM && !!props.story?.templateId) {
                if (hasSufficientSub) {
                  dispatch(setTemplateId(props.story.templateId));
                  dispatch(setTemplateStoryId(props.story.id));
                  router.navigate(paths.storyCreator.template.base);
                } else {
                  router.navigate(paths.subscription.base + `/${requiredSubscription}`);
                }
              }
            }}
          >
            Dopasuj
          </Button>
        </div>
        <Typography variant="description" styles={{ color: cGrey200 }}>
          {IPSUM}
        </Typography>
      </div>
    </div>
  );
};
