import { Divider } from 'features/ui/divider/divider';
import { NoResult } from 'features/ui/search-story/noResult';
import { TagBadge } from 'features/ui/tags/tagBadge';
import { Typography } from 'features/ui/typography/typography';
import _ from 'lodash';
import { useEffect, useState } from 'react';
import { Form } from 'react-bootstrap';
import { InfiniteScroll } from 'shared/hooks/useInfiniteScroll';
import { StorySeries } from 'shared/types/series';
import { useSearchSeriesMutation } from 'store/api/endpoints/seriesEndpoints';
import { Operator, SearchCriteria, SearchOperation } from 'store/api/endpoints/storyEndpoints';
import { useAppDispatch } from 'store/hooks';
import { addAlert } from 'store/slices/alertsSlice';
import { AdminSeriesPreview } from './adminSeriesPreview';

const PAGE_SIZE = 6;

type Props = {
  baseSearchCriteria?: SearchCriteria[];
  onSearchFocusChange: (isSearchFocused: boolean) => void;
  onSearchTextChange: (searchText: string) => void;
  onStorySeriesClick: (storySeries: StorySeries) => void;
};

export const AdminSeriesSearch = (props: Props) => {
  const [searchText, setSearchText] = useState('');
  const [isSearchFocused, setIsSearchFocused] = useState(false);

  const [titleSeries, setTitleSeries] = useState<StorySeries[]>([]);
  const [titleSeriesCount, setTitleSeriesCount] = useState<number>(0);
  const [titleSeriesTotalPages, setTitleSeriesTotalPages] = useState<number>(0);
  const [titleSeriesCurrentPage, setTitleSeriesCurrentPage] = useState<number>(0);

  //redux
  const dispatch = useAppDispatch();
  // rtk
  const [searchTitleSeries, { isLoading: areTitleSeriesLoading }] = useSearchSeriesMutation();

  const { onSearchFocusChange, onSearchTextChange } = props;

  useEffect(() => {
    onSearchFocusChange(isSearchFocused);
  }, [onSearchFocusChange, isSearchFocused]);

  useEffect(() => {
    onSearchTextChange(searchText);
  }, [onSearchTextChange, searchText]);

  const requestTitleSeries = (page: number, searchCriteria: SearchCriteria[]) => {
    searchTitleSeries({
      page: page,
      size: PAGE_SIZE,
      searchRequest: {
        operator: Operator.AND,
        searchCriteriaGroups: [
          { groupOperator: Operator.AND, searchCriteria: [...(props.baseSearchCriteria ?? [])] },
          { groupOperator: Operator.AND, searchCriteria: searchCriteria }
        ]
      }
    })
      .unwrap()
      .then(result => {
        setTitleSeries(prev => (page > 0 ? [...prev, ...result.content] : result.content));
        setTitleSeriesCount(result.totalElements);
        setTitleSeriesTotalPages(result.totalPages);
      })
      .catch(() => dispatch(addAlert({ color: 'danger', text: 'Nie udało się wyszukać bajek po tytule' })));
  };

  const onSearch = (event: React.ChangeEvent<HTMLInputElement>) => {
    const value = event.target.value;
    setSearchText(value);
    const currentPage = 0;
    setTitleSeriesCurrentPage(currentPage);

    if (_.isEmpty(value)) {
      setTitleSeries([]);
      setTitleSeriesCount(0);
      return;
    }

    const titleCriteria = {
      filterKey: 'title',
      value: value,
      operation: SearchOperation.CONTAINS
    };

    requestTitleSeries(currentPage, [titleCriteria]);
  };

  const loadMore = () => {
    const titleCriteria = {
      filterKey: 'title',
      value: searchText,
      operation: SearchOperation.CONTAINS
    };

    const nextPage = titleSeriesCurrentPage + 1;
    setTitleSeriesCurrentPage(nextPage);
    requestTitleSeries(nextPage, [titleCriteria]);
  };
  let series: StorySeries[] = [];
  let totalPages: number = 0;
  let currentPage: number = 0;
  series = titleSeries;
  totalPages = titleSeriesTotalPages;
  currentPage = titleSeriesCurrentPage;

  const isLoading = areTitleSeriesLoading;

  const isNoResult = !isLoading && !_.isEmpty(searchText) && _.isEmpty(series);

  const infiniteScrollSeries = series.map(s => ({ ...s, id: s.id, label: s.title }));

  return (
    <>
      <Form.Group>
        <span className="fa fa-search form-control-feedback"></span>
        <Form.Control
          type="text"
          placeholder="Czego szukasz?"
          onChange={onSearch}
          onFocus={() => setIsSearchFocused(true)}
          onBlur={() => setIsSearchFocused(false)}
        />
      </Form.Group>

      <Divider spacing="my-4" />

      {(isSearchFocused || !_.isEmpty(searchText)) && (
        <>
          {!_.isEmpty(searchText) && (
            <div className="d-flex flex-wrap gap-2 pb-4">
              <TagBadge value={`Tytuł (${titleSeriesCount})`} isActive={true} onClickCallback={_.noop} />
            </div>
          )}
          <div className="d-flex flex-column">
            <InfiniteScroll
              divider
              list={infiniteScrollSeries}
              isLoading={isLoading}
              hasMore={currentPage !== totalPages - 1}
              loadMore={loadMore}
              renderListItem={element => <AdminSeriesPreview storySeries={element} onStorySeriesClick={props.onStorySeriesClick} />}
              renderEmptyListMessage={() => (isNoResult ? <NoResult /> : <></>)}
              renderLoadingRowItem={() => (
                <div className="d-flex justify-content-center p-4">
                  <Typography variant="h4">Ładowanie...</Typography>
                </div>
              )}
            />
          </div>
        </>
      )}
    </>
  );
};
