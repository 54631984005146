import { StoryCostBadge } from 'features/ui/payments/storyCostBadge';
import { getTotalCost } from 'features/payments/utils.payments';
import { RouteLayout } from 'features/ui/layout/routeLayout';
import { Typography } from 'features/ui/typography/typography';
import { useEffect, useState } from 'react';
import { Button } from 'react-bootstrap';
import { useNavigate } from 'react-router-dom';
import paths from 'routing/utils';
import { AddSquare } from 'shared/icons/addSquare';
import { QuestionMarkIcon } from 'shared/icons/questionMarkIcon';
import { World } from 'shared/types/world';
import { cGrey200, cPrimary, cSecondary } from 'shared/utils/styleCommon';
import { useGetPredefinedWorldsQuery } from 'store/api/endpoints/predefinedWorlds';
import { useAppDispatch, useAppSelector } from 'store/hooks';
import { setTempWorld, setWorld } from 'store/slices/storyCreatorSlice';
import { PickerItemPreview } from '../pickerItemPreview';

const defaultPredefinedWorlds: World[] = [];

export const WorldPicker = () => {
  const [isEditMode, setIsEditMode] = useState(false);
  const [isWorldInitialized, setIsWorldInitialized] = useState(false);
  // redux
  const dispatch = useAppDispatch();
  const loggedUser = useAppSelector(state => state.authSlice.loggedUser);
  const world = useAppSelector(state => state.storyCreatorSlice.world);
  const tempWorld = useAppSelector(state => state.storyCreatorSlice.tempWorld);
  const narrator = useAppSelector(state => state.storyCreatorSlice.narrator);
  // rtk
  const { data: predefinedWorlds = defaultPredefinedWorlds } = useGetPredefinedWorldsQuery();
  // other
  const navigate = useNavigate();

  const totalCost = getTotalCost('custom', narrator);

  useEffect(() => {
    if (!tempWorld && world && !isWorldInitialized) {
      dispatch(setTempWorld(world));
      setIsWorldInitialized(true);
    }
  }, [dispatch, world, tempWorld, isWorldInitialized]);

  return (
    <RouteLayout
      backRoute={paths.storyCreator.custom.base}
      backRouteAdditionalAction={() => {
        dispatch(setTempWorld(undefined));
      }}
      bottomActions={[
        {
          label: 'Zapisz',
          action: () => {
            dispatch(setWorld(tempWorld));
            dispatch(setTempWorld(undefined));
            navigate(paths.storyCreator.custom.base);
          }
        }
      ]}
      actions={[
        {
          icon: <StoryCostBadge cost={totalCost} />
        }
      ]}
    >
      <div className="pt-4 mx-3">
        <div className="d-flex justify-content-between">
          <Typography variant="h1">Wybierz świat</Typography>
          <Button variant="link" onClick={() => setIsEditMode(prev => !prev)}>
            {isEditMode ? 'Zakończ' : 'Edytuj'}
          </Button>
        </div>

        <div className="d-flex justify-content-center align-items-start gap-3 mt-5 flex-wrap">
          <div
            role="button"
            className="d-flex flex-column justify-content-start align-items-start gap-2"
            onClick={() => navigate(paths.storyCreator.custom.world.createWorld)}
          >
            <AddSquare />
            <Typography variant="description" classNames="text-center">
              Dodaj świat
            </Typography>
          </div>
          <div role="button" className="d-flex flex-column justify-content-start" onClick={() => dispatch(setTempWorld({ random: true }))}>
            <div
              style={{
                outline: tempWorld?.random ? `2px solid ${cPrimary}` : `2px solid ${cGrey200}`,
                borderRadius: '10px',
                width: '160px',
                height: '166px'
              }}
              className="d-flex justify-content-center align-items-center"
            >
              <QuestionMarkIcon fill={tempWorld?.random ? cPrimary : '#112B52'} />
            </div>
            <Typography variant="description" classNames="pt-2" styles={{ color: tempWorld?.random ? cPrimary : cSecondary }}>
              Losowy
            </Typography>
          </div>
          {loggedUser?.worlds.map(w => (
            <div key={w.value}>
              <PickerItemPreview
                editable={isEditMode}
                isSelected={tempWorld?.world?.value === w.value}
                filename={w.image}
                directory={loggedUser?.id}
                name={w.value}
                onClickCallback={() => {
                  dispatch(setTempWorld({ random: false, world: w }));

                  if (isEditMode) {
                    navigate(paths.storyCreator.custom.world.editWorld + `/${w.value}`);
                  }
                }}
              />
            </div>
          ))}
          {predefinedWorlds.map(w => (
            <div key={w.value}>
              <PickerItemPreview
                isSelected={tempWorld?.world?.value === w.value}
                filename={w.image}
                directory={'common'}
                name={w.value}
                onClickCallback={() => {
                  dispatch(setTempWorld({ random: false, world: { ...w, predefined: true } }));
                }}
              />
            </div>
          ))}
        </div>
      </div>
    </RouteLayout>
  );
};
