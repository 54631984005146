import { StoryCreatorPaymentNotificationHandler } from 'features/payments/modals/handlers/storyCreatorPaymentNotificationHandler';
import { getTotalCost } from 'features/payments/utils.payments';
import { CustomImage } from 'features/ui/image/customImage';
import { RouteLayout } from 'features/ui/layout/routeLayout';
import { StoryCostBadge } from 'features/ui/payments/storyCostBadge';
import { Typography } from 'features/ui/typography/typography';
import _ from 'lodash';
import { useEffect, useState } from 'react';
import { Button, Modal } from 'react-bootstrap';
import { useLocation } from 'react-router-dom';
import { router } from 'routing/routes';
import paths from 'routing/utils';
import { WhiteCloud } from 'shared/images/whiteCloud';
import { NarratorType, PredefinedNarrator } from 'shared/types/narrator';
import { CustomStoryParams } from 'shared/types/story';
import { scrollToElement } from 'shared/utils/commonUtils';
import { cPrimary } from 'shared/utils/styleCommon';
import { useGetLoggedUserQuery } from 'store/api/endpoints/accountEndpoints';
import { useGenerateCustomStoryMutation } from 'store/api/endpoints/generatorEndpoints';
import { useGetPredefinedNarratorsQuery } from 'store/api/endpoints/predefinedNarratorsEndpoints';
import { useGetPredefinedSoundtracksQuery } from 'store/api/endpoints/predefinedSoundtracksEndpoints';
import { useAppDispatch, useAppSelector } from 'store/hooks';
import { addAlert } from 'store/slices/alertsSlice';
import { setSource } from 'store/slices/paymentSlice';
import { resetCreatorState, setNarrator, setSoundtrack } from 'store/slices/storyCreatorSlice';
import { resetStoryGeneratorState, setIsGenerating } from 'store/slices/storyGeneratorSlice';
import { CreateStoryButtonBottomElement } from './createStoryButton';
import { ElementsTile } from './element-tiles/elementsTile';
import { EpochTile } from './element-tiles/epochTile';
import { HeroesTile } from './element-tiles/heroesTile';
import { MoralTile } from './element-tiles/moralTile';
import { NarratorTile } from './element-tiles/narratorTile';
import { RecipientTile } from './element-tiles/recipientTile';
import { StoryLengthTile } from './element-tiles/storyLengthTile';
import { WorldTile } from './element-tiles/worldTile';
import { WritingStyleTile } from './element-tiles/writingStyleTile';
import { InsufficientTokensModal } from './insufficientTokensModal';
import { StoryCreatorLoader } from './loader/storyCreatorLoader';
import { PredefinedSoundtrack, SoundtrackType } from 'shared/types/soundtrack';

const defaultPredefinedNarrators: PredefinedNarrator[] = [];
const defaultSoundtracks: PredefinedSoundtrack[] = [];

export const CustomStoryCreator = () => {
  const [isConfirmationDialogOpen, setIsConfirmationDialogOpen] = useState<boolean>(false);
  const [isInsufficientTokensModalOpen, setIsInsufficientTokensModalOpen] = useState<boolean>(false);
  const [isTopImageLoaded, setIsTopImageLoaded] = useState(false);
  // redux
  const storyCreatorSlice = useAppSelector(state => state.storyCreatorSlice);
  const generatedStoryId = useAppSelector(state => state.storyGeneratorSlice.generatedStoryId);
  const isGenerating = useAppSelector(state => state.storyGeneratorSlice.isGenerating);
  const narrator = useAppSelector(state => state.storyCreatorSlice.narrator);
  const soundtrack = useAppSelector(state => state.storyCreatorSlice.soundtrack);
  const selectedTileId = useAppSelector(state => state.storyCreatorSlice.selectedTileId);
  const loggedUser = useAppSelector(state => state.authSlice.loggedUser);
  const dispatch = useAppDispatch();
  // rtk
  const { refetch: refetchUser } = useGetLoggedUserQuery();
  const { data: predefinedNarrators = defaultPredefinedNarrators } = useGetPredefinedNarratorsQuery();
  const { data: soundtracks = defaultSoundtracks } = useGetPredefinedSoundtracksQuery();
  const [generate] = useGenerateCustomStoryMutation();
  // other
  const location = useLocation();

  useEffect(() => {
    if (selectedTileId && isTopImageLoaded) {
      const element = document.getElementById(selectedTileId);
      element && scrollToElement(element);
    }
  }, [selectedTileId, isTopImageLoaded]);

  useEffect(() => {
    if (!narrator) {
      const freeNarrator = predefinedNarrators.find(pn => pn.type === NarratorType.NORMAL);
      freeNarrator && dispatch(setNarrator(freeNarrator));
    }
  }, [dispatch, narrator, predefinedNarrators]);

  useEffect(() => {
    if (!soundtrack) {
      const defaultSoundtrack = soundtracks.find(s => s.type === SoundtrackType.WRONA);
      defaultSoundtrack && dispatch(setSoundtrack(defaultSoundtrack));
    }
  }, [dispatch, soundtrack, soundtracks]);

  useEffect(() => {
    if (!_.isNil(generatedStoryId)) {
      dispatch(resetStoryGeneratorState());
      dispatch(resetCreatorState());
      refetchUser();
      router.navigate(paths.story + `/${generatedStoryId}`, { state: 'FROM_GENERATOR' });
    }
  }, [dispatch, generatedStoryId, refetchUser]);

  const generateCustomStory = () => {
    dispatch(setIsGenerating(true));

    if (!storyCreatorSlice.narrator) {
      dispatch(addAlert({ color: 'warning', text: 'Lektor nie został wybrany' }));
      return;
    }

    if (!storyCreatorSlice.soundtrack) {
      dispatch(addAlert({ color: 'warning', text: 'Muzyka w tle nie została wybrana' }));
      return;
    }

    const body: CustomStoryParams = {
      narrator: storyCreatorSlice.narrator,
      soundtrack: storyCreatorSlice.soundtrack,
      recipient: storyCreatorSlice.recipient,
      mainHero: storyCreatorSlice.mainHero,
      sideHero: storyCreatorSlice.sideHero,
      world: storyCreatorSlice.world,
      moral: storyCreatorSlice.moral,
      epoch: storyCreatorSlice.epoch,
      tags: storyCreatorSlice.elements,
      writingStyle: storyCreatorSlice.writingStyle,
      storyLength: storyCreatorSlice.storyLength
    };

    generate(body)
      .unwrap()
      .catch(() => {
        dispatch(setIsGenerating(false));
        dispatch(addAlert({ color: 'danger', text: 'Nie udało się utworzyć bajki' }));
      });
  };

  const handleStoryGeneration = () => {
    if ((loggedUser?.tokens ?? 0) < totalCost) {
      setIsInsufficientTokensModalOpen(true);
      return;
    }
    let points = 0;
    points += storyCreatorSlice.recipient ? 1 : 0;
    points += storyCreatorSlice.mainHero?.character ? 1 : 0;
    points += storyCreatorSlice.sideHero?.character ? 1 : 0;
    points += storyCreatorSlice.elements?.predefinedTags ? 1 : 0;
    points += storyCreatorSlice.world?.world ? 1 : 0;
    points += storyCreatorSlice.moral?.moral ? 1 : 0;
    points += storyCreatorSlice.epoch?.predefinedEpoch ? 1 : 0;
    points += storyCreatorSlice.writingStyle?.predefinedWritingStyle ? 1 : 0;
    points >= 3 ? generateCustomStory() : setIsConfirmationDialogOpen(true);
  };

  const totalCost = getTotalCost('custom', narrator);

  return isGenerating ? (
    <StoryCreatorLoader />
  ) : (
    <RouteLayout
      hideBottomNavigation
      backRoute={location.key !== 'default' ? -1 : paths.root}
      bottomElement={<CreateStoryButtonBottomElement createStory={handleStoryGeneration} disabled={false} storyType="custom" />}
    >
      <StoryCreatorPaymentNotificationHandler storyType="custom" onAcceptGeneratorPaymentSuccess={handleStoryGeneration} />
      <InsufficientTokensModal
        open={isInsufficientTokensModalOpen}
        totalCost={totalCost}
        onClose={() => {
          setIsInsufficientTokensModalOpen(false);
        }}
        onAccept={() => {
          setIsInsufficientTokensModalOpen(false);
          dispatch(setSource(paths.storyCreator.custom.base));
          router.navigate(paths.payments.packages);
        }}
      />
      <Modal centered show={isConfirmationDialogOpen} onHide={() => setIsConfirmationDialogOpen(false)}>
        <Modal.Header closeButton></Modal.Header>
        <Modal.Body className="p-4">
          <Typography variant="h1" classNames="pb-3">
            Dopasuj bajkę
          </Typography>
          <Typography classNames="pb-2" variant="description">
            Sugerujemy wprowadzenie przynajmniej:
          </Typography>
          <Typography classNames="pb-1" variant="description">
            - Dla kogo
          </Typography>
          <Typography classNames="pb-2" variant="description">
            - Bohaterów
          </Typography>
          <Typography classNames="pb-5" variant="description">
            Pamiętaj, im więcej powiesz o swoich preferencjach tym bardziej dopasowaną bajkę stworzysz.
          </Typography>
          <div className="d-flex flex-column align-items-center justify-content-center gap-3">
            <Button className="fullWidth" variant="primary" onClick={generateCustomStory}>
              Zaskocz mnie
            </Button>
            <Button className="fullWidth" variant="outline-primary" onClick={() => setIsConfirmationDialogOpen(false)}>
              Dopasuj bajkę
            </Button>
          </div>
        </Modal.Body>
      </Modal>

      <div style={{ position: 'relative' }}>
        <CustomImage directory="common" filename="create-story.png" width="100%" onLoad={() => setIsTopImageLoaded(true)} />
        <WhiteCloud />
      </div>
      <div className="mx-3 pb-4">
        <div className="d-flex justify-content-between align-items-center">
          <Typography variant="h1" styles={{ paddingBottom: '40px' }}>
            Twoja Bajka
          </Typography>
          <StoryCostBadge cost={totalCost} />
        </div>

        <div className="d-flex flex-column gap-2">
          <RecipientTile customCreator tileId="RecipientTile" />

          <div className="w-100 my-2" style={{ borderBottom: `2px solid ${cPrimary}` }} />

          <HeroesTile customCreator tileId="HeroesTile" />
          <NarratorTile customCreator tileId="NarratorTile" />
          {/* <SoundtrackTile customCreator tileId="SoundtrackFile" /> */}
          <WorldTile tileId="WorldTile" />
          <MoralTile tileId="MoralTile" />
          <ElementsTile tileId="ElementsTile" />
          <WritingStyleTile tileId="WritingStyleTile" />
          <EpochTile tileId="EpochTile" />
          <StoryLengthTile tileId="StoryLengthTile" />
        </div>
      </div>
    </RouteLayout>
  );
};
