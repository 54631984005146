import { Story } from 'shared/types/story';
import { Template, TemplateParam } from 'shared/types/template';
import { HTTP_METHODS, apiSlice } from '../api';

interface TemplateCreateUpdateRequest {
  templateDTO: Template;
  storyDTO: Omit<Story, 'id' | 'image'>;
}
interface TemplateCreateUpdateResponse {
  templateDTO: Template;
  storyDTO: Story;
}

const extendedApi = apiSlice.injectEndpoints({
  endpoints: builder => ({
    getTemplates: builder.query<Template[], void>({
      query: () => ({
        url: `/templates`,
        method: HTTP_METHODS.GET
      }),
      providesTags: result =>
        result
          ? [...result.map(({ id }) => ({ type: 'Template' as const, id })), { type: 'Template', id: 'LIST' }]
          : [{ type: 'Template', id: 'LIST' }]
    }),
    getTemplateById: builder.query<Template, string>({
      query: id => ({
        url: `/templates/${id}`,
        method: HTTP_METHODS.GET
      })
    }),
    getTemplateParams: builder.query<TemplateParam[], string>({
      query: templateId => ({
        url: `/templates/${templateId}/template-params`,
        method: HTTP_METHODS.GET
      })
    }),
    createTemplate: builder.mutation<TemplateCreateUpdateResponse, TemplateCreateUpdateRequest>({
      query: request => ({
        url: '/templates',
        method: HTTP_METHODS.POST,
        body: request
      })
    }),
    updateTemplate: builder.mutation<TemplateCreateUpdateResponse, TemplateCreateUpdateRequest>({
      query: request => ({
        url: '/templates',
        method: HTTP_METHODS.PUT,
        body: request
      })
    }),
    uploadStoryImage: builder.mutation<void, { storyId: string; file: File }>({
      query: data => {
        const formData = new FormData();
        formData.append('file', data.file);

        return {
          url: `/stories/${data.storyId}/image`,
          method: HTTP_METHODS.PUT,
          body: formData
        };
      }
    }),
    generateStoryAudio: builder.mutation<void, { storyId: string; narratorId: string }>({
      query: data => {
        return {
          url: `/stories/audio`,
          method: HTTP_METHODS.POST,
          body: { storyId: data.storyId, narratorId: data.narratorId }
        };
      }
    }),
    isGeneratingAudio: builder.query<boolean, void>({
      query: () => {
        return {
          url: `/audio/generating`,
          method: HTTP_METHODS.GET
        };
      }
    })
  }),
  overrideExisting: false
});

export const {
  useGetTemplatesQuery,
  useGetTemplateByIdQuery,
  useGetTemplateParamsQuery,
  useCreateTemplateMutation,
  useUpdateTemplateMutation,
  useUploadStoryImageMutation,
  useGenerateStoryAudioMutation,
  useIsGeneratingAudioQuery
} = extendedApi;
