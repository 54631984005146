import { DragDropContext, Draggable, Droppable } from '@hello-pangea/dnd';
import { skipToken } from '@reduxjs/toolkit/query';
import { Divider } from 'features/ui/divider/divider';
import { FormError } from 'features/ui/form/formError';
import { ImageEdit } from 'features/ui/image-edit/imageEdit';
import { JustifyCenter } from 'features/ui/layout/justifyCenter';
import { RouteLayout } from 'features/ui/layout/routeLayout';
import { Typography } from 'features/ui/typography/typography';
import { useEffect, useState } from 'react';
import { Button, Col, Container, Form, Row } from 'react-bootstrap';
import { Controller, useForm } from 'react-hook-form';
import { useSearchParams } from 'react-router-dom';
import { router } from 'routing/routes';
import paths from 'routing/utils';
import { useFetchBlob } from 'shared/hooks/useFetchBlob';
import { StorySeries } from 'shared/types/series';
import { Creator, StoryPreview } from 'shared/types/story';
import { validationMessage } from 'shared/utils/formUtils';
import {
  useCreateSeriesMutation,
  useGetSeriesByIdQuery,
  useUpdateSeriesMutation,
  useUploadSeriesImageMutation
} from 'store/api/endpoints/seriesEndpoints';
import { Operator, SearchOperation, useSearchStoriesMutation } from 'store/api/endpoints/storyEndpoints';
import { useAppDispatch } from 'store/hooks';
import { addAlert } from 'store/slices/alertsSlice';
import trashIcon from '../../shared/icons/trash_icon.svg';
import { AdminStoryList } from './adminStoryList';

type StoryEditorFormValues = {
  title: string;
};

const orderStoriesByEpisodes = (series: StorySeries, stories: StoryPreview[]): StoryPreview[] => {
  const episodeMap = new Map(series.episodes.map(episode => [episode.episodeId, episode.index]));

  const orderedStories = stories
    .filter(story => episodeMap.has(story.storyId))
    .sort((a, b) => (episodeMap.get(a.storyId) ?? 0) - (episodeMap.get(b.storyId) ?? 0));

  return orderedStories;
};

export const AdminSeriesEditor = () => {
  const [selectedStories, setSelectedStories] = useState<StoryPreview[]>([]);
  const [avatar, setAvatar] = useState<File | null>(null);
  // redux
  const dispatch = useAppDispatch();
  // rtk
  const [searchParams] = useSearchParams();
  const seriesId = searchParams.get('seriesId');
  const { data: series, refetch: refetchSeries } = useGetSeriesByIdQuery(seriesId ?? skipToken);
  const [createSeries] = useCreateSeriesMutation();
  const [updateSeries] = useUpdateSeriesMutation();
  const [uploadImage] = useUploadSeriesImageMutation();
  const [searchStory, { data: stories }] = useSearchStoriesMutation();
  // other
  const { blob: imageBlob } = useFetchBlob(series?.image, 'common');

  const onSubmit = (data: StoryEditorFormValues) => {
    if (series) {
      updateSeries({
        ...series,
        title: data.title,
        episodes: selectedStories.map((ss, idx) => ({ index: idx, episodeId: ss.storyId }))
      })
        .unwrap()
        .then(async response => {
          if (avatar && response.id) {
            try {
              await uploadImage({ seriesId: response.id, file: avatar })
                .unwrap()
                .catch(e => console.error(e));
            } catch (error) {
              dispatch(addAlert({ color: 'danger', text: 'Nie udało się zapisać obrazu bajki.' }));
            }
          }

          await refetchSeries().unwrap();

          dispatch(addAlert({ color: 'success', text: 'Edytowano serię', clearable: false }));
          router.navigate(paths.admin.series.list);
        })
        .catch(e => console.error(e));
    } else {
      createSeries({
        creator: Creator.SYSTEM,
        ownerId: 'system-user',
        title: data.title,
        episodes: selectedStories.map((ss, idx) => ({ index: idx, episodeId: ss.storyId }))
      })
        .unwrap()
        .then(async response => {
          if (avatar && response.id) {
            try {
              await uploadImage({ seriesId: response.id, file: avatar })
                .unwrap()
                .catch(e => console.error(e));
            } catch (error) {
              dispatch(addAlert({ color: 'danger', text: 'Nie udało się zapisać obrazu bajki.' }));
            }
          }

          dispatch(addAlert({ color: 'success', text: 'Utworzono serię', clearable: false }));
          router.navigate(paths.admin.series.list);
        })
        .catch(e => console.error(e));
    }
  };

  const {
    control,
    handleSubmit,
    formState: { isValid },
    reset: resetForm
  } = useForm<StoryEditorFormValues>({
    mode: 'onBlur'
  });

  useEffect(() => {
    if (series) {
      resetForm({
        title: series.title
      });
    }
  }, [series, resetForm]);

  useEffect(() => {
    if (stories && series) {
      setSelectedStories(orderStoriesByEpisodes(series, stories.content));
    }
  }, [stories, series]);

  useEffect(() => {
    if (series) {
      searchStory({
        page: 0,
        size: series.episodes.length,
        searchRequest: {
          operator: Operator.AND,
          searchCriteriaGroups: [
            {
              groupOperator: Operator.AND,
              searchCriteria: [
                {
                  filterKey: 'id',
                  values: series.episodes.map(e => e.episodeId),
                  operation: SearchOperation.INCLUDES
                }
              ]
            }
          ]
        }
      });
    }
  }, [series, searchStory]);

  const addStoryToList = (story: StoryPreview) => {
    setSelectedStories(prev => {
      if (prev.some(p => p.storyId === story.storyId)) {
        dispatch(addAlert({ color: 'warning', text: 'Bajka, którą próbujesz dodać znajduje się już na liście.' }));
        return prev;
      } else {
        return [...prev, story];
      }
    });
  };

  const removeStoryFromList = (story: StoryPreview) => {
    setSelectedStories(prev => prev.filter(ss => ss.storyId !== story.storyId));
  };

  const handleOnDragEnd = (result: any) => {
    const { destination, source } = result;
    if (!destination) {
      return;
    }
    const updatedItems = Array.from(selectedStories);
    const [reorderedItem] = updatedItems.splice(source.index, 1);
    updatedItems.splice(destination.index, 0, reorderedItem);
    setSelectedStories(updatedItems);
  };

  return (
    <RouteLayout hideBottomNavigation backRoute={paths.admin.series.base}>
      <Container style={{ maxWidth: 'none' }}>
        <Row className="justify-content-sm-center">
          <Col xl={6}>
            <Typography variant="h1" classNames="mb-5">
              Obraz
            </Typography>

            <ImageEdit onFileChangeCallback={setAvatar} file={imageBlob} />

            <Divider spacing="my-5" />
            <Form onSubmit={handleSubmit(onSubmit)} noValidate>
              <Form.Group className="mb-3" controlId="storyEditorForm.title">
                <Form.Label>Tytuł</Form.Label>
                <Controller
                  name="title"
                  control={control}
                  render={({ field, fieldState }) => (
                    <>
                      <Form.Control {...field} placeholder="Tytuł" isInvalid={fieldState.invalid} />
                      {fieldState.invalid ? <FormError error={fieldState.error?.message} /> : ''}
                    </>
                  )}
                  rules={{
                    required: validationMessage.required
                  }}
                />
              </Form.Group>

              <DragDropContext onDragEnd={handleOnDragEnd}>
                <Droppable droppableId="droppable-list">
                  {provided => (
                    <ul {...provided.droppableProps} ref={provided.innerRef} style={{ listStyleType: 'none', padding: 0 }}>
                      {selectedStories?.map((story, index) => (
                        <Draggable key={story.storyId} draggableId={story.storyId} index={index}>
                          {provided => (
                            <div
                              className="d-flex justify-content-between align-items-center gap-2"
                              ref={provided.innerRef}
                              {...provided.draggableProps}
                              {...provided.dragHandleProps}
                              style={{
                                userSelect: 'none',
                                ...provided.draggableProps.style
                              }}
                              role="button"
                            >
                              <div className="d-flex align-items-center gap-3">
                                <i className="bi bi-grip-vertical"></i>
                                <Typography variant="h5">{index + 1}</Typography>

                                <Typography variant="description">{story.title}</Typography>
                              </div>
                              <div style={{ width: '24px', height: '24px' }} onClick={() => removeStoryFromList(story)} role="button">
                                <img src={trashIcon} alt="" width={'100%'} />
                              </div>
                            </div>
                          )}
                        </Draggable>
                      ))}
                    </ul>
                  )}
                </Droppable>
              </DragDropContext>
              <JustifyCenter classNames="pt-5 pb-2">
                <Button type="submit" disabled={!isValid}>
                  Zapisz
                </Button>
              </JustifyCenter>
            </Form>
          </Col>
          <Col xl={6}>
            <AdminStoryList onStoryClick={addStoryToList} />
          </Col>
        </Row>
      </Container>
    </RouteLayout>
  );
};
