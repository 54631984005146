// IMPORTANT!!! Keep this file clean, if you see code exceeds more than 50 - 100 lines, break common utilities to smaller files

import chroma from 'chroma-js';
import { router } from 'routing/routes';
import paths from 'routing/utils';
import scrollIntoView from 'scroll-into-view-if-needed';
import { addAlert } from 'store/slices/alertsSlice';
import { store } from 'store/store';

export function pxToRem(number: number, baseNumber = 16): string {
  return `${number / baseNumber}rem`;
}

function hexToRgb(color: string): string {
  return chroma(color).rgb().join(', ');
}

export function rgba(colorInHex: string, opacity: number): string {
  return `rgba(${hexToRgb(colorInHex)}, ${opacity})`;
}

export function toHHMMSS(timeString: number): string {
  const sec_num = timeString;
  const hours = Math.floor(sec_num / 3600);
  const minutes = Math.floor((sec_num - hours * 3600) / 60);
  const seconds = sec_num - hours * 3600 - minutes * 60;

  let formattedTime = '';

  if (hours > 0) {
    const paddedHours = hours < 10 ? '0' + hours : hours.toString();
    formattedTime += paddedHours + ':';
  }

  const paddedMinutes = minutes < 10 ? '0' + minutes : minutes.toString();
  const paddedSeconds = seconds < 10 ? '0' + seconds : seconds.toString();

  formattedTime += paddedMinutes + ':' + paddedSeconds;

  return formattedTime;
}

export const validateRequestAuthStatus = (responseStatus: number) => {
  if (responseStatus === 401) {
    router.navigate(paths.logout);
    store.dispatch(
      addAlert({
        color: 'danger',
        text: 'Nie udało się przetworzyć Twojego żądania. Może to być spowodowane wygaśnięciem sesji. Zaloguj się ponownie, aby kontynuować.',
        clearable: false
      })
    );
  }
};

export const scrollToElement = (htmlNode?: Element) => {
  if (htmlNode) {
    scrollIntoView(htmlNode, { behavior: 'smooth', scrollMode: 'if-needed', inline: 'end' });
  }
};
