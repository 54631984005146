import { useEffect } from 'react';
import Spinner from 'react-bootstrap/esm/Spinner';
import { useNavigate, useSearchParams } from 'react-router-dom';

export const Campaign = () => {
  const [searchParams] = useSearchParams();
  const redirectUrl = searchParams.get('redirect');
  const navigate = useNavigate();

  useEffect(() => {
    if (redirectUrl) {
      window.open(redirectUrl, '_self');
    }
  }, [redirectUrl, navigate]);

  return <Spinner animation="border" role="status" />;
};
