import _ from 'lodash';
import { useEffect, useState } from 'react';
import { useAppDispatch, useAppSelector } from 'store/hooks';
import { resetPaymentState } from 'store/slices/paymentSlice';
import { PaymentErrorModal } from '../paymentErrorModal';
import { PaymentSuccessModal } from '../paymentSuccessModal';

export const DefaultPaymentNotificationHandler = () => {
  const [isPaymentSuccessModalOpen, setIsPaymentSuccessModalOpen] = useState(false);
  const [isPaymentErrorModalOpen, setIsPaymentErrorModalOpen] = useState(false);
  const [tokens, setTokens] = useState(0);
  // redux
  const paymentInfo = useAppSelector(state => state.paymentSlice.paymentInfo);
  const dispatch = useAppDispatch();

  useEffect(() => {
    if (!_.isNil(paymentInfo)) {
      if (paymentInfo.status === 'SUCCESS') {
        setIsPaymentSuccessModalOpen(true);
        setTokens(paymentInfo.tokens ?? 0);
      } else {
        setIsPaymentErrorModalOpen(true);
      }
      dispatch(resetPaymentState());
    }
  }, [paymentInfo, dispatch]);

  return (
    <>
      <PaymentSuccessModal open={isPaymentSuccessModalOpen} tokens={tokens} onClose={() => setIsPaymentSuccessModalOpen(false)} />
      <PaymentErrorModal open={isPaymentErrorModalOpen} onClose={() => setIsPaymentErrorModalOpen(false)} />
    </>
  );
};
