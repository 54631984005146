import { CustomImage } from 'features/ui/image/customImage';
import { Typography } from 'features/ui/typography/typography';
import { StorySeries } from 'shared/types/series';
import { rgba } from 'shared/utils/commonUtils';
import { cWhite } from 'shared/utils/styleCommon';

type Props = {
  storySeries: StorySeries;
  onStorySeriesClick: (storySeries: StorySeries) => void;
};

function inflect(count: number): string {
  if (count === 1) {
    return 'odcinek';
  } else if (count >= 2 && count <= 4) {
    return 'odcinki';
  } else {
    return 'odcinków';
  }
}

export const SeriesPreview = (props: Props) => {
  const episodesSize = props.storySeries.episodes.length;

  return (
    <div
      className="m-1"
      role="button"
      style={{
        position: 'relative',
        borderRadius: '10px',
        overflow: 'hidden',
        width: 218,
        height: 260
      }}
      onClick={() => props.onStorySeriesClick(props.storySeries)}
    >
      <CustomImage
        directory="common"
        filename={props.storySeries.image}
        styles={{ position: 'relative', borderRadius: '10px', zIndex: 1 }}
        height={'260px'}
        width={'218px'}
        skeleton
      />
      <CustomImage
        directory="common"
        filename={'series_dark_layer.png'}
        styles={{ position: 'absolute', top: 0, left: 0, borderRadius: '10px', zIndex: 2 }}
        width={'218px'}
        skeleton
      />
      <CustomImage
        directory="common"
        filename={'blue_cloud.png'}
        styles={{ position: 'absolute', bottom: 0, left: 0, zIndex: 3 }}
        width={'218px'}
      />

      <div
        className="d-flex flex-column justify-content-end align-items-start h-100 p-3"
        style={{ position: 'absolute', bottom: 0, left: 0, zIndex: 4 }}
      >
        <Typography classNames="pt-2" variant="h5" styles={{ color: cWhite }}>
          {props.storySeries.title}
        </Typography>
        <Typography variant="description-sm" classNames="pt-1" styles={{ color: rgba(cWhite, 0.6) }}>
          {episodesSize} {inflect(episodesSize)}
        </Typography>
      </div>
    </div>
  );
};
