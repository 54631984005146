import { Divider } from 'features/ui/divider/divider';
import { RouteLayout } from 'features/ui/layout/routeLayout';
import { Typography } from 'features/ui/typography/typography';
import { Button } from 'react-bootstrap';
import { useNavigate } from 'react-router-dom';
import paths from 'routing/utils';
import { cGrey600, cPrimary } from 'shared/utils/styleCommon';
import mailOutlineIcon from '../../shared/icons/mail_outline.svg';
import { GoogleButton } from './googleButton';
import { HeaderStorytailo } from './headerStorytailo';
import { TermsAndPrivacyLinks } from './termsAndPrivacyLinks';

export const SignUp = (): JSX.Element => {
  const navigate = useNavigate();

  return (
    <RouteLayout backRoute={-1}>
      <HeaderStorytailo headerText="Zarejestruj się" />
      <div className="d-flex flex-column align-items-center gap-3 mx-4 mb-4" style={{ paddingTop: 32 }}>
        <GoogleButton label="Załóż konto z Google" />
        <div className="mb-4">
          <TermsAndPrivacyLinks />
        </div>

        <Button
          variant="text-primary fullWidth"
          className="long d-flex align-items-center gap-2"
          onClick={() => navigate(paths.signUp.email)}
        >
          <img src={mailOutlineIcon} alt="" />
          Utwórz konto z email
        </Button>

        <Divider spacing={'my-2'} />
        <div className="d-flex flex-column align-items-center mx-4 mb-4">
          <Typography variant="description-md" styles={{ color: cGrey600 }}>
            Masz już konto?
          </Typography>
          <Typography
            variant="description-md"
            styles={{ color: cPrimary, textDecoration: 'underline', cursor: 'pointer' }}
            onClick={() => navigate(paths.signIn.options)}
          >
            Zaloguj się
          </Typography>
        </div>
      </div>
    </RouteLayout>
  );
};
